import React from "react";
import { Sector } from "recharts";

export const capitalizeName = str => {
  if (str) {
    if (str === "Wohnen auf Zeit") {
      return str;
    }
    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0]?.toUpperCase() + str[i].substr(1);
    }

    return str.join(" ");
  } else {
    return "";
  }
};

export const renderActiveShape = (props, isTheFirstDiagram) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  const isInRightSide = cos >= 0;

  const oppositeDY18 =
    payload.sum_product_area >= 0 && payload.sum_product_area < 7 ? -7 : 25;
  const oppositeDY36 =
    payload.sum_product_area >= 0 && payload.sum_product_area < 7 ? 11 : 45;

  const _pricePercentage =
    payload._price_percentage === null
      ? 0
      : payload._price_percentage && payload._price_percentage.toFixed(0);
  const pricePercentage =
    payload.price_percentage === null
      ? 0
      : payload._price_percentage && payload._price_percentage.toFixed(0);
  const _areaPercentage =
    payload._area_percentage === null ? 0 : payload._area_percentage && payload._area_percentage.toFixed(0);
  const areaPercentage =
    payload.area_percentage === null ? 0 : payload.area_percentage && payload.area_percentage.toFixed(0);


  const textArrFordiagram1 = [
    {
      fill: "#333",
      name: `${capitalizeName(
        payload.name
      )} (Mieten) ${payload.sum_product_area?.toFixed(
        0
      )}m2 | ${areaPercentage}%`,
      dy: oppositeDY18
    },
    {
      fill: "#333",
      name: `Davon vermietet: ${payload._sum_product_area?.toFixed(
        0
      )}m2 | ${_areaPercentage}%`,
      dy: oppositeDY36
    }
  ];
  const textArrFordiagram2 = [
    {
      fill: "#333",
      name: `${capitalizeName(
        payload.name
      )} (Mieten) ${payload.sum_product_price?.toFixed(
        0
      )}m2 | ${pricePercentage}%`,
      dy: oppositeDY18
    },
    {
      fill: "#333",
      name: `Davon vermietet: ${payload._sum_product_price?.toFixed(
        0
      )}m2 | ${_pricePercentage}%`,
      dy: oppositeDY36
    }
  ];

  const textArr = isTheFirstDiagram ? textArrFordiagram1 : textArrFordiagram2;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        dataKey="value"
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

      {textArr.map((text, idx) => {
        return (
          <text
            key={idx}
            x={ex + (isInRightSide ? 20 : -10)}
            y={ey - 15}
            dy={text.dy}
            textAnchor={textAnchor}
            fill={text.fill}
            fontSize={isInRightSide ? `12px` : `10px`}
          >
            {text.name}
          </text>
        );
      })}
    </g>
  );
};

export const checkEmptyCharts = array => {
  let values = [];
  array &&
    array.forEach(item => {
      values.push(Object.values(item)[0]);
    });
  return values.every(i => i === 0);
};
