const featureSearchStyle = theme => ({
  splitResultsContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  errorWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontWeight: "bold",
    fontSize: "1.2rem",
    justifyContent: "center",
    marginBottom: "15px",
    "& h4": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
      height: "100%",
      width: "60%",
      margin: "0",
      borderRadius: "5px"
    }
  }
});

export default featureSearchStyle;
