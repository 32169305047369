const productTableStyles = theme => ({
  fieldContainer: {
    display: "flex",
    border: "2px solid darkgrey",
    margin: "17px 15px 5px 15px",
    background: "ghostwhite",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: "15px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginRight: "9px",
      flexDirection: "column"
    }
  },
  filterButton: {
    height: "20px",
    width: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "5px"
    }
  },
  checkBox: {
    marginTop: "40px"
  },
  productHeader: {
    fontWeight: "bold"
  }
});

export default productTableStyles;
