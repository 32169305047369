import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { TablePagination } from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { geocodeByAddress } from "react-places-autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import Table from "../Table/Table";
import Popover from "../Popover/Popover";
import CardFooter from "../Card/CardFooter";
import TablePaginationActions from "../PaginatorActions/PaginatorActions";
import GridContainer from "../Grid/GridContainer";
import Loader from "../../ui/Loader/Loader";
import HighPerformerService from "../../services/HighPerformerService";
import HeaderContentComponent from "./HeaderContent.component";
import * as actionCreator from "../../store/actions/index";
import styles from "./styles/performerTableStyle";

const useStyles = makeStyles(styles);

const PerformerTable = props => {
  const classes = useStyles();
  const cardHeaderClasses = classNames({
    [classes.cardHeaderPadding]: true,
    [classes.cardHeaderBold]: true
  });

  const [page, setPage] = useState({
    long: 0,
    short: 0,
    rentPrice: 0,
    buyPrice: 0,
    object: 0
  });
  const [rowsPerPage, setRowsPerPage] = useState({
    long: 10,
    short: 10,
    rentPrice: 10,
    buyPrice: 10,
    object: 10
  });
  const [duration, setDuration] = useState(90);
  const [rentPriceDuration, setRentPriceDuration] = useState(90);
  const [buyPriceDuration, setBuyPriceDuration] = useState(90);

  const {
    shortTermData,
    longTermData,
    shortTermRentData,
    shortTermBuyData,
    shortTermDataLoading,
    longTermDataLoading,
    shortTermRentDataLoading,
    shortTermBuyDataLoading,
    objectTableLoading,
    getShortTermPerformer,
    getLongTermPerformer,
    getShortTermRentPerformer,
    getShortTermBuyPerformer,
    getObjectTableData,
    searchParams,
    changeSearchParam,
    category,
    setSingle,
    objectPerformerTable
  } = props;
  const {
    longTermableHead,
    shortTermableHead,
    longTermTableBodyData,
    shortTermTableBodyData,
    shortTermBuyTableHead,
    shortTermRentTableHead,
    shortTermRentTableBodyData,
    shortTermBuyTableBodyData,
    objectTableHead,
    objecTableBodyData
  } = HighPerformerService;

  const longTableData = longTermTableBodyData(
    longTermData.data,
    page.long,
    rowsPerPage.long
  );
  const shortTableData = shortTermTableBodyData(
    shortTermData.data,
    page.short,
    rowsPerPage.short
  );
  const shortRentTableData = shortTermRentTableBodyData(
    shortTermRentData.data,
    page.rentPrice,
    rowsPerPage.rentPrice
  );
  const shortBuyTableData = shortTermBuyTableBodyData(
    shortTermBuyData.data,
    page.buyPrice,
    rowsPerPage.buyPrice
  );

  const objectTableData = objecTableBodyData(
    objectPerformerTable.data,
    page.object,
    rowsPerPage.object
  );

  useEffect(() => {
    getShortTermPerformer(
      searchParams,
      rowsPerPage.long,
      page.long + 1,
      duration
    );
    getLongTermPerformer(searchParams, rowsPerPage.short, page.short + 1);

    //do the same for other 2 tables
    getShortTermRentPerformer(
      searchParams,
      rowsPerPage.rentPrice,
      page.rentPrice + 1,
      rentPriceDuration
    );
    getShortTermBuyPerformer(
      searchParams,
      rowsPerPage.buyPrice,
      page.buyPrice + 1,
      buyPriceDuration
    );
    //object table data fetching
    getObjectTableData(searchParams, rowsPerPage.object, page.object + 1);
    // eslint-disable-next-line
  }, []);

  const onChangePageHandler = (p, type) => {
    setPage({
      ...page,
      [type]: p
    });
    if (type === "long") {
      getLongTermPerformer(searchParams, rowsPerPage.long, p + 1);
    }
    if (type === "short") {
      getShortTermPerformer(searchParams, rowsPerPage.short, p + 1, duration);
    }
    //do the same for other tables
    if (type === "rentPrice") {
      getShortTermRentPerformer(
        searchParams,
        rowsPerPage.rentPrice,
        p + 1,
        rentPriceDuration
      );
    }
    if (type === "buyPrice") {
      getShortTermBuyPerformer(
        searchParams,
        rowsPerPage.buyPrice,
        p + 1,
        buyPriceDuration
      );
    }
    if (type === "object") {
      getObjectTableData(searchParams, rowsPerPage.object, p + 1);
    }
  };

  const onChangeRowPerPagaHandler = (rPerPage, type) => {
    setRowsPerPage({
      ...rowsPerPage,
      [type]: rPerPage
    });
    if (type === "long") {
      setPage({
        ...page,
        long: 0
      });
      getLongTermPerformer(searchParams, rPerPage, page.long + 1);
    }
    if (type === "short") {
      setPage({
        ...page,
        short: 0
      });
      getShortTermPerformer(searchParams, rPerPage, page.short + 1, duration);
    }
    //do the same for other tables
    if (type === "rentPrice") {
      setPage({
        ...page,
        rentPrice: 0
      });
      getShortTermRentPerformer(
        searchParams,
        rPerPage,
        page.rentPrice + 1,
        rentPriceDuration
      );
    }
    if (type === "buyPrice") {
      setPage({
        ...page,
        buyPrice: 0
      });
      getShortTermBuyPerformer(
        searchParams,
        rPerPage,
        page.buyPrice + 1,
        buyPriceDuration
      );
    }
    if (type === "object") {
      setPage({
        ...page,
        object: 0
      });
      getObjectTableData(searchParams, rPerPage, page.object + 1);
    }
  };

  const onZipClick = zipcode => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    setSingle("totalResults", "");
    geocodeByAddress(`${zipcode} + Germany`).then(data => {
      props.history.push(`/admin/search`);

      url.searchParams.set("address", data[0].formatted_address);
      url.searchParams.set("zipcode", zipcode);
      url.searchParams.set("type_name", category);

      changeSearchParam("address", data[0].formatted_address);
      changeSearchParam("zipcode", zipcode);
      changeSearchParam("category", category);

      const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?${url.search.slice(1)}`;

      window.history.pushState({ path: newUrl }, "", newUrl);
    });
  };

  const onDaysChange = (value, type) => {
    if (type === "duration") {
      if (value < 0) {
        setDuration(0);
      } else if (value >= 5000) {
        setDuration(5000);
      } else setDuration(value);
    }
    if (type === "rentPrice") {
      if (value < 0) {
        setRentPriceDuration(0);
      } else if (value >= 5000) {
        setRentPriceDuration(5000);
      } else setRentPriceDuration(value);
    }
    if (type === "buyPrice") {
      if (value < 0) {
        setBuyPriceDuration(0);
      } else if (value >= 5000) {
        setBuyPriceDuration(5000);
      } else setBuyPriceDuration(value);
    }
  };

  const onFilterClick = type => {
    if (type === "duration") {
      getShortTermPerformer(
        searchParams,
        rowsPerPage.short,
        page.short + 1,
        duration
      );
    }
    //do the same for other 2 tables
    if (type === "rentPrice") {
      getShortTermRentPerformer(
        searchParams,
        rowsPerPage.rentPrice,
        page.rentPrice + 1,
        rentPriceDuration
      );
    }
    if (type === "buyPrice") {
      getShortTermBuyPerformer(
        searchParams,
        rowsPerPage.buyPrice,
        page.buyPrice + 1,
        buyPriceDuration
      );
    }
  };

  const onCloseTitleFilter = () => {
    getObjectTableData(searchParams, rowsPerPage.object, page.object + 1);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color={"warning"} className={classes.cardHeaderBold}>
            Top Long Term High Performer Mietrendite (Starting from selected
            timeframe)
          </CardHeader>
          {!longTermDataLoading ? (
            <GridItem xs={12} sm={12} md={12}>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableData={longTableData}
                  tableHead={shortTermableHead}
                  isClickable={true}
                  onCellClick={onZipClick}
                  clickableKey={1}
                />
              </CardBody>
            </GridItem>
          ) : (
            <Loader />
          )}
          <CardFooter>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={3}
              count={longTermData.total || 0}
              onChangePage={(event, newPage) =>
                onChangePageHandler(newPage, "long")
              }
              page={page.long}
              rowsPerPage={rowsPerPage.long}
              onChangeRowsPerPage={event =>
                onChangeRowPerPagaHandler(parseInt(event.target.value), "long")
              }
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: false
              }}
              ActionsComponent={TablePaginationActions}
            />
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color={"info"} className={cardHeaderClasses}>
            <div className={classes.headerContainer}>
              <GridItem xs={6} sm={6} md={8}>
                <div>
                  Top Short Term High Performer Mietrendite (For your choosen
                  duration)
                </div>
              </GridItem>
              <GridItem xs={6} sm={6} md={4}>
                <HeaderContentComponent
                  duration={duration}
                  onDaysChange={onDaysChange}
                  onFilterClick={onFilterClick}
                  isBtnDisabled={shortTermDataLoading}
                  type="duration"
                />
              </GridItem>
            </div>
          </CardHeader>
          {!shortTermDataLoading ? (
            <GridItem xs={12} sm={12} md={12}>
              <CardBody>
                <Table
                  tableHeaderColor="info"
                  tableData={shortTableData}
                  tableHead={longTermableHead}
                  isClickable={true}
                  onCellClick={onZipClick}
                  clickableKey={1}
                />
              </CardBody>
            </GridItem>
          ) : (
            <Loader />
          )}
          <CardFooter>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={3}
              count={shortTermData.total || 0}
              onChangePage={(event, newPage) =>
                onChangePageHandler(newPage, "short")
              }
              page={page.short}
              rowsPerPage={rowsPerPage.short}
              onChangeRowsPerPage={event =>
                onChangeRowPerPagaHandler(parseInt(event.target.value), "short")
              }
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: false
              }}
              ActionsComponent={TablePaginationActions}
            />
          </CardFooter>
        </Card>
      </GridItem>
      {/*rent table*/}
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color={"success"} className={cardHeaderClasses}>
            <div className={classes.headerContainer}>
              <GridItem xs={6} sm={6} md={8}>
                <div>
                  Top Short Term High Perfomer Rent Price per qm (For your
                  choosen duration)
                </div>
              </GridItem>
              <GridItem xs={6} sm={6} md={4}>
                <HeaderContentComponent
                  duration={rentPriceDuration}
                  onDaysChange={onDaysChange}
                  onFilterClick={onFilterClick}
                  isBtnDisabled={shortTermRentDataLoading}
                  type="rentPrice"
                />
              </GridItem>
            </div>
          </CardHeader>
          {!shortTermRentDataLoading ? (
            <GridItem xs={12} sm={12} md={12}>
              <CardBody>
                <Table
                  tableHeaderColor="success"
                  tableData={shortRentTableData}
                  tableHead={shortTermRentTableHead}
                  isClickable={true}
                  onCellClick={onZipClick}
                  clickableKey={1}
                />
              </CardBody>
            </GridItem>
          ) : (
            <Loader />
          )}
          <CardFooter>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={3}
              count={shortTermRentData.total || 0}
              onChangePage={(event, newPage) =>
                onChangePageHandler(newPage, "rentPrice")
              }
              page={page.rentPrice}
              rowsPerPage={rowsPerPage.rentPrice}
              onChangeRowsPerPage={event =>
                onChangeRowPerPagaHandler(
                  parseInt(event.target.value),
                  "rentPrice"
                )
              }
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: false
              }}
              ActionsComponent={TablePaginationActions}
            />
          </CardFooter>
        </Card>
      </GridItem>
      {/*buy table*/}
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color={"danger"} className={cardHeaderClasses}>
            <div className={classes.headerContainer}>
              <GridItem xs={6} sm={6} md={8}>
                <div>
                  Top Short Term High Perfomer by Buy Price per qm (For your
                  choosen duration)
                </div>
              </GridItem>
              <GridItem xs={6} sm={6} md={4}>
                <HeaderContentComponent
                  duration={buyPriceDuration}
                  onDaysChange={onDaysChange}
                  onFilterClick={onFilterClick}
                  isBtnDisabled={shortTermBuyDataLoading}
                  type="buyPrice"
                />
              </GridItem>
            </div>
          </CardHeader>
          {!shortTermBuyDataLoading ? (
            <GridItem xs={12} sm={12} md={12}>
              <CardBody>
                <Table
                  tableHeaderColor="danger"
                  tableData={shortBuyTableData}
                  tableHead={shortTermBuyTableHead}
                  isClickable={true}
                  onCellClick={onZipClick}
                  clickableKey={1}
                />
              </CardBody>
            </GridItem>
          ) : (
            <Loader />
          )}
          <CardFooter>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={3}
              count={shortTermBuyData.total || 0}
              onChangePage={(event, newPage) =>
                onChangePageHandler(newPage, "buyPrice")
              }
              page={page.buyPrice}
              rowsPerPage={rowsPerPage.buyPrice}
              onChangeRowsPerPage={event =>
                onChangeRowPerPagaHandler(
                  parseInt(event.target.value),
                  "buyPrice"
                )
              }
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: false
              }}
              ActionsComponent={TablePaginationActions}
            />
          </CardFooter>
        </Card>
      </GridItem>
      {/*new table*/}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color={"warning"} className={classes.cardHeaderBold}>
            High Perfomer Objects
          </CardHeader>
          <GridItem md={12} style={{marginTop: "10px"}}>
            <Popover onClose={onCloseTitleFilter} type="performer" />
          </GridItem>
          {!objectTableLoading ? (
            <GridItem xs={12} sm={12} md={12}>
              <CardBody>
                <Table
                  tableHeaderColor="warning"
                  tableData={objectTableData}
                  tableHead={objectTableHead}
                  isClickable={true}
                  onCellClick={onZipClick}
                  clickableKey={1}
                />
              </CardBody>
            </GridItem>
          ) : (
            <Loader />
          )}
          <CardFooter>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              colSpan={3}
              count={objectPerformerTable.total || 0}
              onChangePage={(event, newPage) =>
                onChangePageHandler(newPage, "object")
              }
              page={page.object}
              rowsPerPage={rowsPerPage.object}
              onChangeRowsPerPage={event =>
                onChangeRowPerPagaHandler(parseInt(event.target.value), "object")
              }
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: false
              }}
              ActionsComponent={TablePaginationActions}
            />
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  const { highPerformer, search } = state;
  return {
    shortTermData: highPerformer.shortTermData,
    longTermData: highPerformer.longTermData,
    searchParams: highPerformer.searchParams,
    longTermDataLoading: highPerformer.longTermDataLoading,
    shortTermDataLoading: highPerformer.shortTermDataLoading,
    shortTermRentDataLoading: highPerformer.shortTermRentDataLoading,
    shortTermBuyDataLoading: highPerformer.shortTermBuyDataLoading,
    objectTableLoading: highPerformer.objectTableLoading,
    categories: search.categories,
    category: highPerformer.searchParams.category,
    shortTermRentData: highPerformer.shortTermRentData,
    shortTermBuyData: highPerformer.shortTermBuyData,
    objectPerformerTable: highPerformer.objectTableData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getShortTermPerformer: (params, rowsPerPage, page, days) =>
      dispatch(
        actionCreator.getShortTermPerformer(params, rowsPerPage, page, days)
      ),
    getLongTermPerformer: (params, rowsPerPage, page) =>
      dispatch(actionCreator.getLongtTermPerformer(params, rowsPerPage, page)),
    getShortTermRentPerformer: (params, rowsPerPage, page, days) =>
      dispatch(
        actionCreator.getShortTermRentPerformer(params, rowsPerPage, page, days)
      ),
    getShortTermBuyPerformer: (params, rowsPerPage, page, days) =>
      dispatch(
        actionCreator.getShortTermBuyPerformer(params, rowsPerPage, page, days)
      ),
    getObjectTableData: (params, rowsPerPage, page) =>
      dispatch(actionCreator.getObjectTableDataAsync(params, rowsPerPage, page)),
    changeSearchParam: (property, value) =>
      dispatch(actionCreator.changeSearchParam(property, value)),
    setSingle: (property, value) => dispatch(actionCreator.set(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PerformerTable));
