import { combineReducers } from "redux";

// reducers
import authReducer from "./reducers/auth.reducer";
import dashboardReducer from "./reducers/dashboard.reducer";
import uiReducer from "./reducers/ui.reducer";
import searchReducer from "./reducers/search.reducer";
import resultsReducer from "./reducers/results.reducer";
import varianceReducer from "./reducers/variance.reducer";
import notificationReducer from "./reducers/notification.reducer";
import marketAnalyseReducer from "./reducers/market-analyse/market-analyse.reducer";
import featuresSearchReducer from "./reducers/features-search.reducer";
import productTableReducer from "./reducers/product-table.reducer";
import highPerformerReducer from "./reducers/high-performer.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  ui: uiReducer,
  search: searchReducer,
  results: resultsReducer,
  variance: varianceReducer,
  notification: notificationReducer,
  marketAnalyse: marketAnalyseReducer,
  featuresSearch: featuresSearchReducer,
  productTable: productTableReducer,
  highPerformer: highPerformerReducer
});

export default rootReducer;
