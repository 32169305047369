import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { primaryColor } from "../../assets/jss/material-dashboard-react";
import DateFnsUtils from "@date-io/date-fns";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor[0]
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:hover:not($disabled):before,&:before": {
          borderColor: "#D2D2D2 !important",
          borderWidth: "1px !important"
        }
      }
    }
  }
});
const CustomDatePicker = props => {
  theme.overrides.MuiPickersDay = {
    daySelected: {
      border: "1px solid rgba(156, 39, 176, 0.35)",
      "&:hover": {
        color: "#fff"
      }
    }
  };

  const { views, label, selectedDate, onChange, onClickAway, format } = props;

  const minDate = new Date("2017-01-01");
  const maxDate = new Date();

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          disableToolbar
          disableFuture
          value={selectedDate}
          views={views}
          variant="inline"
          animateYearScrolling={false}
          format={format}
          onChange={onChange}
          onClose={onClickAway}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default CustomDatePicker;
