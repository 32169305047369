import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Aux from "hoc/AuxReact";

// CORE COMPONENTS
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import WrapperWithLoader from "components/WrapperWithLoader/WrapperWithLoader.component";

// COMPONENTS
import Form from "components/MarketAnalyse/Form/Form.component";
import PieChartDiagram from "components/MarketAnalyse/PieChartDiagram/PieChartDiagram.component";
import MarketAnalyseTable from "components/MarketAnalyse/Table/MarketAnalyseTable.component";

// ACTIONS
import { resetData } from "store/reducers/market-analyse/market-analyse.actions";

// STYLES
import styles from "./MarketAnalyse.styles";
const useStyles = makeStyles(styles);

const MarketAnalyse = props => {
  const {
    graphicsDataIsLoading,
    graphicsData,
    tableData,
    resetData,
    errorMessage
  } = props;
  const classes = useStyles();
  useEffect(() => {
    return () => {
      resetData();
    };
  }, [resetData]);

  return (
    <Aux>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Form />
        </GridItem>
        {errorMessage && (
          <div className={classes.errorMessage}>{errorMessage}</div>
        )}
        <GridItem xs={12} sm={12} md={12} lg={12} relative>
          <GridContainer>
            <WrapperWithLoader
              isFetched={!!graphicsData}
              isLoading={graphicsDataIsLoading}
            >
              <GridItem xs={12} sm={12} md={12} lg={12} relative>
                <PieChartDiagram graphicsData={graphicsData} />
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.table}
              >
                <MarketAnalyseTable tableData={tableData} />
              </GridItem>
            </WrapperWithLoader>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Aux>
  );
};

const mapStateToProps = state => {
  const { marketAnalyse } = state;
  return {
    graphicsData: marketAnalyse.graphicsData,
    tableData: marketAnalyse.tableData,
    graphicsDataIsLoading: marketAnalyse.graphicsDataIsLoading,
    errorMessage: marketAnalyse.errorMessage
  };
};

const mapDispatchToProps = dispatch => ({
  resetData: () => dispatch(resetData())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketAnalyse);
