import api from "../../../config/api";
import axios from "axios";

let source = null;
const MarketAnalyseApi = {
  getDiagramData(params) {
    return api.post("market-analyze", params);
  },
  getSuggestions({ name, value, city, zip }) {
    if (source) source.cancel();
    source = axios.CancelToken.source();

    const params = {};
    if (name === "zip") {
      if (city) {
        params.city = city;
      }
    }
    if (name === "city") {
      if (zip) {
        params.zip = zip;
      }
    }
    return api.get(`features/typing/${name}`, {
      params: {
        type: name,
        q: value,
        ...params
      },
      cancelToken: source.token
    });
  }
};

export default MarketAnalyseApi;
