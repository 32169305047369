const authStyles = {
  loaderContainer: {
    display: "flex",
    alignItems: "center"
  },
  showLoader: {
    display: "block"
  },
  hideLoader: {
    display: "none"
  }
};

export default authStyles;
