import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";

// core components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";

import styles from "./styles/cardToolStyle";
import CardFooter from "../Card/CardFooter";

const useStyles = makeStyles(styles);

const CardTool = props => {
  const classes = useStyles();
  const { text, textNumber, title, value, className } = props;

  return (
    <Card
      style={{
        marginTop: 0
      }}
      className={className}
    >
      {text && (
        <CardBody>
          <h3 className={classes.cardTitle}>{text} - <b>{textNumber}</b></h3>
        </CardBody>
      )}
      {title && (
        <CardBody>
          <h3 className={classes.cardTitle}>{title}</h3>
        </CardBody>
      )}
      {value && (
        <CardFooter>
          <div className={classes.stats}>
            <p>{value}</p>
          </div>
        </CardFooter>
      )}
    </Card>
  );
};

export default CardTool;
