import * as actionTypes from "../actions/actionTypes";
import HighPerformerApi from "../../api/HighPerformerApi";

export const changePerformerSearchParam = (name, value) => {
  return {
    type: actionTypes.CHANGE_PERFORMER_SEARCH_PARAM,
    name,
    value
  };
};

const getShortTermPerformerDataStart = () => {
  return {
    type: actionTypes.GET_SHORT_TERM_PERFORMER_DATA_START
  };
};
const getLongtTermPerformerDataStart = () => {
  return {
    type: actionTypes.GET_LONG_TERM_PERFORMER_DATA_START
  };
};
const getShortTermRnetPerformerDataStart = () => {
  return {
    type: actionTypes.GET_SHORT_TERM_RENT_PERFORMER_DATA_START
  };
};
const getShortTermBuyPerformerDataStart = () => {
  return {
    type: actionTypes.GET_SHORT_TERM_BUY_PERFORMER_DATA_START
  };
};
const getObjectTableDataStart = () => {
  return {
    type: actionTypes.GET_OBJECT_TABLE_DATA_START
  };
};

const setShortTermPerformerData = data => {
  return {
    type: actionTypes.GET_SHORT_TERM_PERFORMER_DATA_SUCCESS,
    data
  };
};
const setLongTermPerformerData = data => {
  return {
    type: actionTypes.GET_LONG_TERM_PERFORMER_DATA_SUCCESS,
    data
  };
};
const setShortTermRentPerformerData = data => {
  return {
    type: actionTypes.GET_SHORT_TERM_RENT_PERFORMER_DATA_SUCCESS,
    data
  };
};
const setShortTermBuyPerformerData = data => {
  return {
    type: actionTypes.GET_SHORT_TERM_BUY_PERFORMER_DATA_SUCCESS,
    data
  };
};
const setObjectTableData = data => {
  return {
    type: actionTypes.GET_OBJECT_TABLE_DATA_SUCCESS,
    data
  };
};

export const getShortTermPerformer = (
  params,
  rowsPerPage,
  page,
  days
) => async dispatch => {
  dispatch(getShortTermPerformerDataStart());

  try {
    const response = await HighPerformerApi.getShorttermPerformer(
      params,
      rowsPerPage,
      page,
      days
    );
    const data = response.data.data;
    dispatch(setShortTermPerformerData(data));
  } catch (e) {
    console.log(e);
  }
};

export const getLongtTermPerformer = (
  params,
  rowsPerPage,
  page
) => async dispatch => {
  dispatch(getLongtTermPerformerDataStart());

  try {
    const response = await HighPerformerApi.getLongtermPerformer(
      params,
      rowsPerPage,
      page
    );
    const data = response.data.data;
    dispatch(setLongTermPerformerData(data));
  } catch (e) {
    console.log(e);
  }
};

export const getShortTermRentPerformer = (
  params,
  rowsPerPage,
  page,
  days
) => async dispatch => {
  dispatch(getShortTermRnetPerformerDataStart());

  try {
    const response = await HighPerformerApi.getShortTermRentPerformer(
      params,
      rowsPerPage,
      page,
      days
    );
    const data = response.data.data;
    dispatch(setShortTermRentPerformerData(data));
  } catch (e) {
    console.log(e);
  }
};

export const getShortTermBuyPerformer = (
  params,
  rowsPerPage,
  page,
  days
) => async dispatch => {
  dispatch(getShortTermBuyPerformerDataStart());

  try {
    const response = await HighPerformerApi.getShortTermBuyPerformer(
      params,
      rowsPerPage,
      page,
      days
    );
    const data = response.data.data;
    dispatch(setShortTermBuyPerformerData(data));
  } catch (e) {
    console.log(e);
  }
};

export const getObjectTableDataAsync = (
  params,
  rowsPerPage,
  page,
  days
) => async dispatch => {
  dispatch(getObjectTableDataStart());

  try {
    const response = await HighPerformerApi.getObjectTableData(
      params,
      rowsPerPage,
      page,
      days
    );
    const data = response.data.data;
    dispatch(setObjectTableData(data));
  } catch (e) {
    console.log(e);
  }
};
