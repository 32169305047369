const fieldGroupStyle = theme => ({
  fieldContainer: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  mgTop35: {
    marginTop: "35px"
  },
  icons: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
    marginTop: "10px"
  },
  green: {
    color: "#388e3c"
  },
  red: {
    color: "red"
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  }
});

export default fieldGroupStyle;
