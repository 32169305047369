import actionTypes from "./market-analyse.types";

const initialState = {
  graphicsData: null,
  tableData: null,
  errorMessage: null,
  // LOADERS
  graphicsDataIsLoading: false,
  // suggestion helpers
  typingSuggestions: [],
  isSuggestionsModal: false,
  suggestionsObject: {}
};

const marketAnalyseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_MARKET_ANALYSE_DATA:
      return initialState;
    case actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_START:
      return {
        ...state,
        graphicsDataIsLoading: true
      };
    case actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_SUCCESS:
      return {
        ...state,
        graphicsData: action.payload.chart_rows,
        tableData: action.payload.rows,
        graphicsDataIsLoading: false
      };
    case actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_FAILURE:
      return {
        graphicsDataIsLoading: false,
        errorMessage: action.payload
      };
    case actionTypes.GET_TYPING_SUGGESTIONS:
      return {
        ...state,
        typingSuggestions: action.payload,
        isSuggestionsModal: true,
        suggestionsObject: action.data
      };
    case actionTypes.CLOSE_SUGGESTION_MODAL:
      return {
        ...state,
        isSuggestionsModal: false
      };

    default:
      return state;
  }
};

export default marketAnalyseReducer;
