const HighPerformerService = {
  longTermTableBodyData(arr, page, rowsPerPage) {
    let tableData = [];

    arr &&
      arr.map((item, index) => {
        const number = {
          label: page * rowsPerPage + index + 1
        };
        const zip = {
          label: item.product_zip
        };
        const city = {
          label: item.product_city
        };
        const mietrendite = {
          label: `${item.mietrendite !== null ? `${item.mietrendite} %` : "―"}`
        };

        if (tableData[index] === undefined) {
          tableData[index] = [];
        }

        return tableData[index].push(number, zip, city, mietrendite);
      });
    return tableData;
  },
  shortTermTableBodyData(arr, page, rowsPerPage) {
    let tableData = [];

    arr &&
      arr.map((item, index) => {
        const number = {
          label: page * rowsPerPage + index + 1
        };
        const zip = {
          label: item.product_zip
        };
        const city = {
          label: item.product_city
        };
        const growth = {
          label: `${item.growth !== null ? `${item.growth.toFixed(2)} %` : "―"}`
        };

        if (tableData[index] === undefined) {
          tableData[index] = [];
        }

        return tableData[index].push(number, zip, city, growth);
      });
    return tableData;
  },
  shortTermRentTableBodyData(arr, page, rowsPerPage) {
    let tableData = [];

    arr &&
      arr.map((item, index) => {
        const number = {
          label: page * rowsPerPage + index + 1
        };
        const zip = {
          label: item.product_zip
        };
        const city = {
          label: item.product_city
        };
        const growth = {
          label: `${item.growth !== null ? `${item.growth.toFixed(2)} %` : "―"}`
        };

        if (tableData[index] === undefined) {
          tableData[index] = [];
        }

        return tableData[index].push(number, zip, city, growth);
      });
    return tableData;
  },
  shortTermBuyTableBodyData(arr, page, rowsPerPage) {
    let tableData = [];

    arr &&
      arr.map((item, index) => {
        const number = {
          label: page * rowsPerPage + index + 1
        };
        const zip = {
          label: item.product_zip
        };
        const city = {
          label: item.product_city
        };
        const growth = {
          label: `${item.growth !== null ? `${item.growth.toFixed(2)} %` : "―"}`
        };

        if (tableData[index] === undefined) {
          tableData[index] = [];
        }

        return tableData[index].push(number, zip, city, growth);
      });
    return tableData;
  },
  objecTableBodyData(arr, page, rowsPerPage) {
    let tableData = [];

    arr &&
    arr.map((item, index) => {
      const number = {
        label: page * rowsPerPage + index + 1
      };
      const zip = {
        label: item.product_zip
      };
      const name = {
        label: sliceText(item.product_name, 20),
        url: `/admin/property-view/${item.product_id}`,
        urlComesFrom: item.product_url
      };
      const city = {
        label: item.product_city
      };
      const price = {
        label: `${parseInt(item.product_price).toLocaleString()} €`
      };
      const area = {
        label: item.product_covered_area
      };
      const _pricePerQM = item.product_price/item.product_covered_area
      const pricePerQM = {
        label: `${_pricePerQM !== null ? `${_pricePerQM.toFixed(2)} €` : "―"}`
      };
      const mietrendite = {
        label: `${item.mietrendite !== null ? `${item.mietrendite} %` : "―"}`
      };

      if (tableData[index] === undefined) {
        tableData[index] = [];
      }

      return tableData[index].push(number, zip, name, city, price, area, pricePerQM, mietrendite);
    });
    return tableData;
  },
  getSingleProductBody(data) {
    delete data.coords;
    delete data.id;
    delete data.images;
    delete data.mainImage;
    delete data.property_id;
    delete data.bedrooms;
    delete data.floors;
    delete data.garage;
    delete data.zip_average_covered_percentage;
    delete data.year_constructed;
    delete data.heating;
    delete data.address;
    delete data.vacant_from;
    delete data.rooms;


    return (
      data &&
      Object.keys(data).map(item => {
        const string = item.charAt(0).toUpperCase() + item.slice(1);
        return [
          {
            label: string.split("_").join(" ")
          },
          {
            label: data[item]
          }
        ];
      })
    );
  },
  getCurrentDate() {
    let d = new Date();

    let month = d.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let year = d.getFullYear();
    return { year, month };
  },
  germanyStatesList: [
    "Deutschland",
    "Baden-Württemberg",
    "Bayern",
    "Berlin",
    "Brandenburg",
    "Bremen",
    "Hamburg",
    "Hessen",
    "Mecklenburg-Vorpommern",
    "Niedersachsen",
    "Nordrhein-Westfalen",
    "Rheinland-Pfalz",
    "Saarland",
    "Sachsen",
    "Sachsen-Anhalt",
    "Schleswig-Holstein",
    "Thüringen"
  ],
  longTermableHead: [
    {
      label: "#"
    },
    {
      label: "Zipcode"
    },
    {
      label: "City"
    },
    {
      label: "Growth"
    }
  ],
  shortTermableHead: [
    {
      label: "#"
    },
    {
      label: "Zipcode"
    },
    {
      label: "City"
    },
    {
      label: "Mietrendite"
    }
  ],
  shortTermRentTableHead: [
    {
      label: "#"
    },
    {
      label: "Zipcode"
    },
    {
      label: "City"
    },
    {
      label: "Growth"
    }
  ],
  shortTermBuyTableHead: [
    {
      label: "#"
    },
    {
      label: "Zipcode"
    },
    {
      label: "City"
    },
    {
      label: "Growth"
    }
  ],
  objectTableHead: [
    {
      label: "#"
    },
    {
      label: "Zipcode"
    },
    {
      label: "Name"
    },
    {
      label: "City"
    },
    {
      label: "Product Price"
    },
    {
      label: "Product Area"
    },
    {
      label: "Price per qm"
    },
    {
      label: "Mietrendite"
    }
  ],

  months: [
    { id: 1, name: "January", value: "01" },
    { id: 2, name: "February", value: "02" },
    { id: 3, name: "March", value: "03" },
    { id: 4, name: "April", value: "04" },
    { id: 5, name: "May", value: "05" },
    { id: 6, name: "June", value: "06" },
    { id: 7, name: "July", value: "07" },
    { id: 8, name: "August", value: "08" },
    { id: 9, name: "September", value: "09" },
    { id: 10, name: "October", value: "10" },
    { id: 11, name: "November", value: "11" },
    { id: 12, name: "December", value: "12" }
  ],
  years: [
    { id: 1, value: "2017" },
    { id: 2, value: "2018" },
    { id: 3, value: "2019" },
    { id: 4, value: "2020" }
  ]
};

const sliceText = (str, length) => {
  if (str.length > length) {
    return `${str.slice(0, length)}...`;
  }
  return str;
};

export default HighPerformerService;
