import api from "../config/api";

const SearchApi = {
  search(params) {
    return api.get("search", {
      params: {
        ...params
      }
    });
  },
  shape(address) {
    return api.get(`search/city-shape/${address}`);
  },
  getMarkers(bounds, saleTypeId, rentTypeId, center, radius, address) {
    return api.get("search/get-markers", {
      params: {
        ...bounds,
        saleTypeId,
        rentTypeId,
        center,
        radius,
        address
      }
    });
  },
  propertyList(params) {
    return api.get("search/property-list", {
      params: {
        ...params
      }
    });
  },
  getPaginatinTotalCount(params) {
    params = JSON.parse(JSON.stringify(params));
    delete params.rowsPerPage;
    delete params.page;

    return api.get("search/property-count", {
      params: {
        ...params
      }
    });
  }
};

export default SearchApi;
