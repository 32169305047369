import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    margin: "0 -15px !important",
    width: "unset"
  },
  relative: {
    position: "relative"
  }
};

const useStyles = makeStyles(styles);

export default function GridContainer(props) {
  const classes = useStyles();
  const { children, relative, className, ...rest } = props;

  const gridStyles = classNames({
    [classes.grid]: true,
    relative: !!relative,
    [className]: className !== undefined
  });
  return (
    <Grid container {...rest} className={gridStyles}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node
};
