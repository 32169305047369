export default {
  RESET_MARKET_ANALYSE_DATA: "RESET_MARKET_ANALYSE_DATA",

  // ASYNC
  // ACTIONS FOR MARKET-ANALYSE
  GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_START:
    "GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_START",
  GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_SUCCESS:
    "GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_SUCCESS",
  GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_FAILURE:
    "GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_FAILURE",
  GET_TYPING_SUGGESTIONS: "GET_TYPING_SUGGESTIONS",
  CLOSE_SUGGESTION_MODAL: "CLOSE_SUGGESTION_MODAL"
};
