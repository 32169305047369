import api from "../config/api";
import axios from "axios";

let source = null;

const FeatureSearchApi = {
  getStreetMarkers({ kgs22 }) {
    return api.get(`get-street-markers/${kgs22}`);
  },
  getFeaturesNames() {
    return api.get("features/name");
  },
  getFeaturesStatuses({ city, zip, streetname, housenumber }) {
    return api.get("features", {
      params: {
        city,
        zip,
        streetname,
        housenumber
      }
    });
  },
  getSuggestions({ type, value, city, zip }) {
    if (source) source.cancel();
    source = axios.CancelToken.source();

    const params = {};
    if (type === "streetname") {
      if (city) {
        params.city = city;
      }
      if (zip) {
        params.zip = zip;
      }
    }
    if (type === "zip") {
      if (city) {
        params.city = city;
      }
    }
    return api.get(`features/typing/${type}`, {
      params: {
        type,
        q: value,
        ...params
      },
      cancelToken: source.token
    });
  }
};

export default FeatureSearchApi;
