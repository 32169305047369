import React, { useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "./customTabsStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTabs(props) {
  const [value, setValue] = useState(0);
  const handleChange = (event, value) => {
    setValue(value);
  };
  const classes = useStyles();
  const { headerColor, plainTabs, tabs, title, rtlActive } = props;
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive
  });

  const handleTabClick = () => {
    const scrollBar = document.querySelector("#fcrimmo-perfect-scrollbar");
    if (
      props.isUpdate !== undefined &&
      props.isUpdate === true &&
      props.handleClick
    ) {
      props.handleClick();
    }
    if (scrollBar.scrollTop > 600) {
      scrollBar.scrollTop = 600;
    } else {
      return;
    }
  };
  return (
    <Card plain={plainTabs} className={classes.card}>
      <CardHeader
        color={headerColor}
        plain={plainTabs}
        className={classes.cardHeaderSticky}
      >
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
            scrollButtons: classes.displayNone
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map(({ TabIcon, tabName }, key) => {
            let icon = {};
            if (TabIcon) icon = { icon: <TabIcon /> };
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper
                }}
                key={key}
                label={tabName}
                onClick={handleTabClick}
                {...icon}
              />
            );
          })}
        </Tabs>
      </CardHeader>
      <CardBody>
        {tabs.map((prop, key) => {
          let tabContentClassName;
          switch (true) {
            case key !== value && prop.tabName === "Calculation":
              tabContentClassName = classes.chartlistHidden;
              break;
            case key !== value:
              tabContentClassName = classes.displayNone;
              break;
            default:
              tabContentClassName = classes.cardBody;
          }
          return (
            <div key={key} className={tabContentClassName}>
              {prop.tabContent}
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool
};
