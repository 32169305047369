import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// MUI COMPONENTS
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import GridItem from "../../Grid/GridItem";

// COMPONENTS
import {
  StyledTableCellTopBottom,
  StyledTableCell,
  StyledTableRow
} from "./TableCells.component";

// UTILITIES
import { reduceValues, capitalizeName, myFixed } from "./table.utilities";

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

const arr = [
  { name: "number_of_objects", mesUint: "" },
  {
    name: "total_area",
    mesUint: (
      <>
        m<sup>2</sup>
      </>
    )
  },
  {
    name: "avg_area",
    mesUint: (
      <>
        m<sup>2</sup>
      </>
    )
  },
  { name: "not_rented_number_of_objects", mesUint: "" },
  {
    name: "not_rented_total_area",
    mesUint: (
      <>
        m<sup>2</sup>
      </>
    )
  },
  { name: "not_rented_avg_area", mesUint: "%" },
  {
    name: "avg_rent_price",
    mesUint: (
      <>
        &euro; / m<sup>2</sup>
      </>
    )
  },
  { name: "rentincome_per_year", mesUint: <>&euro;</> }
];

const MarketAnalyseTable = props => {
  const { tableData } = props;
  const classes = useStyles();

  return tableData.length ? (
    <TableContainer>
      <Table className={classes.table} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <StyledTableCellTopBottom rowSpan={2}>
              Art der Einheit
            </StyledTableCellTopBottom>
            <StyledTableCellTopBottom colSpan="3" align="center">
              Gesamt
            </StyledTableCellTopBottom>
            <StyledTableCellTopBottom colSpan="3" align="center">
              Leerstehend
            </StyledTableCellTopBottom>
            <StyledTableCellTopBottom colSpan="3" align="center">
              Mieteinnahmen IST
            </StyledTableCellTopBottom>
          </TableRow>
          <TableRow>
            <StyledTableCellTopBottom>#</StyledTableCellTopBottom>
            <StyledTableCellTopBottom>Fläche</StyledTableCellTopBottom>
            <StyledTableCellTopBottom>&Oslash;</StyledTableCellTopBottom>
            <StyledTableCellTopBottom>#</StyledTableCellTopBottom>
            <StyledTableCellTopBottom>Fläche</StyledTableCellTopBottom>
            <StyledTableCellTopBottom>%</StyledTableCellTopBottom>
            <StyledTableCellTopBottom>
              AVG price per m<sup>2</sup>
            </StyledTableCellTopBottom>
            <StyledTableCellTopBottom>
              Rent income per year
            </StyledTableCellTopBottom>
            <StyledTableCellTopBottom>%</StyledTableCellTopBottom>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, idx) => {
            return (
              <StyledTableRow key={idx}>
                <StyledTableCell>{capitalizeName(row.name)}</StyledTableCell>

                {arr.map(({ name, mesUint }, idx) => {
                  return (
                    <StyledTableCell key={idx}>
                      {row[name] !== null && row[name] !== 0 ? (
                        <React.Fragment>
                          {myFixed(row[name], 2)}
                          <span> {mesUint}</span>
                        </React.Fragment>
                      ) : (
                        "―"
                      )}
                    </StyledTableCell>
                  );
                })}

                <StyledTableCell>{myFixed(row.avg_priceand, 2)}%</StyledTableCell>
              </StyledTableRow>
            );
          })}

          <TableRow>
            <StyledTableCellTopBottom>Alle</StyledTableCellTopBottom>
            {arr.map((prop, idx) => {
              return (
                <StyledTableCellTopBottom key={idx}>
                  <span>
                    {reduceValues(tableData, prop.name)?.toFixed(0) || "―"}
                  </span>
                  <span>{prop.mesUint}</span>
                </StyledTableCellTopBottom>
              );
            })}
            <StyledTableCellTopBottom>
              {/*{reduceValues(tableData, "avg_priceand")?.toFixed(0)}%*/}
            </StyledTableCellTopBottom>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <GridItem xs={12} sm={12} md={12} lg={12}>
      <h3>Couldn&apos;t find any result.</h3>
    </GridItem>
  );
};

export default MarketAnalyseTable;
