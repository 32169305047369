import MarketAnalyseApi from "./market-analyse.api";
import actionTypes from "./market-analyse.types";

export const resetData = () => {
  return {
    type: actionTypes.RESET_MARKET_ANALYSE_DATA
  };
};

const getMarketAnalyseDataAsyncStart = () => {
  return {
    type: actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_START
  };
};
const getMarketAnalyseDataAsyncSuccess = diagramData => {
  return {
    type: actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_SUCCESS,
    payload: diagramData
  };
};
const getMarketAnalyseDataAsyncFailure = errorMessage => {
  return {
    type: actionTypes.GET_MARKET_ANALYSE_DIAGRAM_DATA_ASYNC_FAILURE,
    payload: errorMessage
  };
};
const getTypingSuggestions = (suggestions, data) => {
  return {
    type: actionTypes.GET_TYPING_SUGGESTIONS,
    payload: suggestions,
    data: data
  };
};

export const closeSuggestionModal = () => {
  return {
    type: actionTypes.GET_TYPING_SUGGESTIONS
  };
};

export const getFieldTypingSuggestions = params => async dispatch => {
  const { name } = params;
  try {
    const response = await MarketAnalyseApi.getSuggestions(params);
    const data = response.data.data;
    const suggestions = { [name]: [] };

    data.forEach(d => {
      if (name === "zip") {
        suggestions[name].push(d.zip_city);
      }
      if (name === "city") {
        suggestions[name].push(d.city);
      }
    });
    dispatch(getTypingSuggestions(suggestions, data));
  } catch (e) {
    return null;
  }
};

export const getMarketAnalyseDataAsync = params => async dispatch => {
  const searchParams = {};
  searchParams.zip = params.zip;
  searchParams.city = params.city;
  searchParams.categories = params.categories;

  if (params.groups.length > 0) {
    searchParams.groups = params.groups.filter(
      group => group.name !== "" && group.list.length > 0
    );
  }
  dispatch(getMarketAnalyseDataAsyncStart());
  try {
    const response = await MarketAnalyseApi.getDiagramData(searchParams);
    const data = response.data.data;
    dispatch(getMarketAnalyseDataAsyncSuccess(data));
  } catch (error) {
    dispatch(getMarketAnalyseDataAsyncFailure("Please check the entered data"));
  }
};
