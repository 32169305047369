import * as actionTypes from "../actions/actionTypes";
import * as commonReducer from "./common";
import { updateObject } from "../unitily";

const initState = {
  data: [],
  markers: [],
  typeIds: [],
  zipDetails: {},
  place: "",
  city: "",
  state: "",
  totalResults: "",
  cityShape: null,
  separatedData: {
    sale: "",
    saleBelow: "",
    saleAbove: "",
    rent: "",
    rentBelow: "",
    rentAbove: ""
  },
  graphicsData: {
    sale: [],
    saleBelow: [],
    saleAbove: [],
    rent: [],
    rentBelow: [],
    rentAbove: [],
    saleAvgTimeOnline: [],
    rentAvgTimeOnline: [],
    category: ""
  },
  rows: [],
  count: 0,
  avgSale: 0,
  avgRent: 0,
  openStreetMapPlaceId: "",
  sociographic: "",
  selectedChartParams: {},
  selectedMarker: [],
  randomText: "",
  isFetureSearchSplitted: false,
  propertyTotalCount: ""
};

const set = (state, action) => {
  const updState = { ...state };
  if (action.payload.property === "markers") {
    if (updState.markers.length <= 0) {
      updState[action.payload.property] = action.payload.value;
    }
    if (action.payload.value.length === 0) {
      updState[action.payload.property] = [];
    }
  } else {
    updState[action.payload.property] = action.payload.value;
  }
  return updateObject(state, {
    ...updState
  });
};

const reset = () => {
  return { ...initState };
};

const setSearchResults = (state, action) => {
  const { property, value, index } = action.payload;
  const updState = { ...state };
  updState.fSearchResults[index][property] = value;

  return updateObject(state, {
    ...updState
  });
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.RESULTS_SET:
      return set(state, action);
    case actionTypes.RESULTS_MULTIPLE:
      return commonReducer.save(state, action);
    case actionTypes.RESET_RESULTS:
      return reset();
    case actionTypes.SET_SEARCH_RESULTS:
      return setSearchResults(state, action);
    default:
      return state;
  }
};

export default reducer;
