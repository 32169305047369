import React, { Component } from "react";
import { connect } from "react-redux";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import * as actionCreator from "../../store/actions/index";

import Aux from "../../hoc/AuxReact";

import SearchApi from "../../api/Search.js";
import SociographicApi from "../../api/Sociographic";

// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

import Form from "../../components/Search/Form";
import CustomTabs from "../../components/CustomTabs/CustomTabs";

import GeographicTab from "../../components/Search/Tabs/Geographic";
import SociographicTab from "../../components/Search/Tabs/Sociographic";
import CalculationTab from "../../components/Search/Tabs/Calculation";
import Loader from "../../ui/Loader/Loader.js";

import { withStyles } from "@material-ui/core/styles";
import styles from "./searchStyle";
// icons
import { Explore, Functions, PersonPin } from "@material-ui/icons";
import { generateRandomText } from "../../config/random-text";

class Search extends Component {
  componentWillUnmount() {
    this.props.toggleLoading("statistic", false);
    this.props.toggleLoading("socLoading", false);
  }

  onChangeAddressHandler = address => {
    if (this.props.results.totalResults !== "") {
      this.props.resetResults();
    }
    this.props.changeSearchParam("address", address);
    this.props.toggleLoading("statistic", false);
    this.props.toggleLoading("socLoading", false);
  };

  onSelectHandler = address => {
    this.props.changeSearchParam("address", address);

    geocodeByAddress(address)
      .then(results => {
        return this.geoCodeResults(results, "select");
      })
      .then(latLng => {
        this.props.changeSearchParam("lat", latLng.lat);
        this.props.changeSearchParam("lng", latLng.lng);
      })
      .catch(error => {
        console.error("Error", error);
        this.props.changeSearchParam("address", address);
      });
  };

  onChangeHandler = (event, property) => {
    this.props.toggleLoading("statistic", false);
    this.props.toggleLoading("socLoading", false);

    if (this.props.results.totalResults !== "") {
      this.props.resetResults();
    }

    const value = event.target.value;

    if (
      property === "category" &&
      value === "Wohnung" &&
      parseInt(this.props.searchFormParams.separateValue) === 0
    ) {
      this.props.changeSearchParam("separateValue", 40);
    } else {
      this.props.changeSearchParam("separateValue", 0);
    }

    if (this.props.searchFormParams.radius === "") {
      this.props.changeSearchParam("radius", 0);
    }

    this.props.changeSearchParam(property, value);
  };

  searchRequest = params => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const radius = params.radius === "" ? 0 : params.radius;

    url.searchParams.set("address", params.address);
    url.searchParams.set("zipcode", params.address);
    url.searchParams.set("lant_lang", `${params.lat},${params.lng}`);
    url.searchParams.set("city_place_name", `${params.address}`);
    url.searchParams.set("distance", `${radius}`);
    url.searchParams.set("type_name", `${params.category}`);
    url.searchParams.set("mietrendite", `${params.rentalPercent}`);
    url.searchParams.set("sizeFrom", `${params.sizeFrom}`);
    url.searchParams.set("sizeTo", `${params.sizeTo}`);
    url.searchParams.set("priceFrom", `${params.priceFrom}`);
    url.searchParams.set("priceTo", `${params.priceTo}`);
    url.searchParams.set("yearFrom", `${params.yearFrom}`);
    url.searchParams.set("yearTo", `${params.yearTo}`);
    url.searchParams.set("separateValue", `${params.separateValue}`);

    const newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${url.search.slice(1)}`;

    window.history.pushState({ path: newUrl }, "", newUrl);
    SearchApi.search(params).then(resp => {
      const data = resp.data.data;
      const separatedData = {
        sale: data.sale_general,
        saleAbove: data.sale_more,
        saleBelow: data.sale_less,
        rent: data.rent_general,
        rentAbove: data.rent_more,
        rentBelow: data.rent_less
      };
      this.props.changeSearchParam("rent_general", data.rent_general);

      this.props.setMultiple({
        totalResults: data.general_total,
        separatedData: separatedData,
        typeIds: data.searchedTypeIds
      });

      this.props.toggleLoading("statistic", false);

      this.props.toggleMultiple({
        socLoading: true
      });
      SociographicApi.get(params).then(resp => {
        const data = resp.data.data;
        this.props.setMultiple({
          sociographic: data
        });
        this.props.toggleMultiple({
          socLoading: false
        });
      });
    });
  };

  onSubmitHandler = () => {
    this.props.setSingle("markers", []);
    if (this.props.results.totalResults !== "") {
      this.props.resetResults();
    }
    const params = {
      ...this.props.searchFormParams
    };
    if (params.radius === "") {
      this.props.changeSearchParam("radius", 0);
      params.radius = 0;
    }
    if (params.separateValue === "") {
      this.props.changeSearchParam("separateValue", 0);
      params.separateValue = 0;
    }

    this.props.toggleLoading("statistic", true);
    geocodeByAddress(params.address)
      .then(results => {
        return this.geoCodeResults(results, "");
      })
      .then(latLng => {
        this.props.changeSearchParam("lat", latLng.lat);
        this.props.changeSearchParam("lng", latLng.lng);
        return latLng;
      })
      .then(latLng => {
        const params = { ...this.props.searchFormParams };
        params.lat = latLng.lat;
        params.lng = latLng.lng;
        this.searchRequest({ ...params });
      })
      .catch(error => {
        console.error("Error", error);
        this.props.changeSearchParam("address", "");

        this.props.toggleLoading("statistic", false);
        this.props.toggleLoading("socLoading", false);
      });
  };

  //refactored code, uses in onSelectHandler and in onSubmithandler
  geoCodeResults = (results, eventType) => {
    const result = results[0];
    const types = result.types;
    const city = result.address_components[0].short_name;

    if (eventType === "select") {
      this.props.changeSearchParam("city", city);
    }

    if (types.includes("postal_code")) {
      this.props.changeSearchParam(
        "zipcode",
        result.address_components[0].short_name
      );
    } else {
      if (eventType === "select") {
        this.props.changeSearchParam("zipcode", "");
      }
    }

    this.props.setMultiple({
      city
    });
    this.props.changeSearchParam("areaType", types[0]);

    return getLatLng(result);
  };

  handleUpdateHelper = () => {
    const randomText = generateRandomText();
    this.props.setSingle("randomText", randomText);
  };

  render() {
    const {
      results,
      searchFormParams,
      loaders,
      searchParams,
      classes
    } = this.props;
    return (
      <Aux>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Form
              onChangeAddress={this.onChangeAddressHandler}
              onSelectAddress={this.onSelectHandler}
              onChangeSearchParam={this.onChangeHandler}
              onSubmit={this.onSubmitHandler}
              isLoadding={loaders.statistic || loaders.socLoading}
              params={{
                address: searchFormParams.address,
                radius: searchFormParams.radius,
                category: searchFormParams.category,
                rentalPercent: searchFormParams.rentalPercent,
                sizeFrom: searchFormParams.sizeFrom,
                sizeTo: searchFormParams.sizeTo,
                priceFrom: searchFormParams.priceFrom,
                priceTo: searchFormParams.priceTo,
                yearFrom: searchFormParams.yearFrom,
                yearTo: searchFormParams.yearTo,
                separateValue: searchFormParams.separateValue,
                categories: searchParams.categories
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <div className={classes.searchResult}>
              {loaders.statistic ? <Loader /> : ""}
            </div>
            {results.totalResults !== "" ? (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className={classes.typo}>
                    <h3>{searchFormParams.address}</h3>
                    {results.propertyTotalCount !== "" && (
                      <p>
                        Zu Ihrer Suchanfrage wurden {results.propertyTotalCount}{" "}
                        Inserate gefunden
                      </p>
                    )}
                  </div>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <CustomTabs
                    title={"Tabs: "}
                    headerColor="info"
                    isUpdate={true}
                    handleClick={this.handleUpdateHelper}
                    tabs={[
                      {
                        tabName: "Calculation",
                        tabIcon: Functions,
                        tabContent: <CalculationTab />
                      },
                      {
                        tabName: "Sociographic",
                        tabIcon: PersonPin,
                        tabContent: <SociographicTab />
                      },
                      {
                        tabName: "Geographic",
                        tabIcon: Explore,
                        tabContent: <GeographicTab />
                      }
                    ]}
                  />
                </GridItem>
              </GridContainer>
            ) : (
              ""
            )}
          </GridItem>
        </GridContainer>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  const { search, results, ui } = state;

  return {
    searchFormParams: {
      lat: search.lat,
      lng: search.lng,
      zipcode: search.zipcode,
      address: search.address,
      radius: search.radius,
      rentalPercent: search.rentalPercent,
      sizeFrom: search.sizeFrom,
      sizeTo: search.sizeTo,
      priceFrom: search.priceFrom,
      priceTo: search.priceTo,
      yearFrom: search.yearFrom,
      yearTo: search.yearTo,
      areaType: search.areaType,
      separateValue: search.separateValue,
      category: search.category
      // type: search.type
    },
    searchParams: {
      categories: search.categories
    },
    results: {
      city: results.city,
      state: results.state,
      totalResults: results.totalResults,
      data: results.data,
      sale: results.separatedData.sale,
      saleBelow: results.separatedData.saleBelow,
      saleAbove: results.separatedData.saleAbove,
      rent: results.separatedData.rent,
      rentBelow: results.separatedData.rentBelow,
      rentAbove: results.separatedData.rentAbove,
      sociographic: results.sociographic,
      propertyTotalCount: results.propertyTotalCount
    },
    loaders: {
      statistic: ui.statistic,
      socLoading: ui.socLoading
    },
    randomText: results.randomText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleLoading: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value)),
    changeAddress: value => dispatch(actionCreator.changeAddress(value)),
    changeSearchParam: (property, value) =>
      dispatch(actionCreator.changeSearchParam(property, value)),
    setMultiple: value => dispatch(actionCreator.setMultiple(value)),
    resetResults: () => dispatch(actionCreator.reset()),
    toggleMultiple: value => dispatch(actionCreator.toggleMultiple(value)),
    setSingle: (property, value) => dispatch(actionCreator.set(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Search));
