import { drawerWidth } from "assets/jss/material-dashboard-react.js";

const backdropStyle = theme => ({
  root: {
    zIndex: 99,
    top: 0,
    right: 0,
    left: "unset",
    height: "100%",
    position: "fixed",
    touchAction: "none",
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  rootShort: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  rootFull: {
    width: `calc(100% - 66px)`
  }
});

export default backdropStyle;
