import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel, MenuItem, Typography } from "@material-ui/core";

import Button from "../CustomButtons/Button";
import GridItem from "../Grid/GridItem";
import CustomInput from "../CustomInput/CustomInput";
import CustomSelect from "../CustomSelect/CustomSelect";
import ProductTableService from "../../services/ProductTableService";
import styles from "./styles/productTableStyles";
import CustomDatePicker from "../DatePicker/CustomDatePicker";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(styles);

const FIlterFieldsComponent = ({
  onFilterClick,
  onResetFilterClick,
  formValues,
  updateField,
  isButtonDisabled,
  categories
}) => {
  const classes = useStyles();
  const { formatString, formatDate } = ProductTableService;

  const onDatePickerChange = (value, type) => {
    const date = formatDate(value);
    const e = { target: { value: date } };
    updateField(e, type);
  };

  return (
    <React.Fragment>
      <div className={classes.fieldContainer}>
        <GridItem xs={12} sm={12} md={9} lg={12}>
          <FormControlLabel
            className={classes.checkBox}
            control={
              <Checkbox
                checked={formValues.limited}
                onChange={event => updateField(event, "limited")}
                name="limited"
                color="primary"
              />
            }
            label={
              <Typography style={{ fontSize: "12px" }}>Limited</Typography>
            }
          />
        </GridItem>
        {Object.keys(formValues).map((field, index) => {
          const type =
            field === "product_url" ||
            field === "product_name" ||
            field === "product_location"
              ? "text"
              : "number";
          if (index <= 5) {
            return (
              <GridItem xs={12} sm={12} md={12} key={field}>
                <CustomInput
                  labelText={formatString(field)}
                  id={field}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: type,
                    onChange: event => updateField(event, field),
                    value: formValues[field]
                  }}
                />
              </GridItem>
            );
          } else {
            return "";
          }
        })}
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText="Type"
            id="type"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => updateField(event, "type_property"),
              value: formValues.type_property
            }}
          >
            <MenuItem value={"Kaufen"}>Kaufen</MenuItem>
            <MenuItem value={"Mieten"}>Mieten</MenuItem>
          </CustomSelect>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText="Category"
            id="category"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => updateField(event, "category"),
              value: formValues.category
            }}
          >
            {categories.length > 0 &&
              categories.map((category, index) => (
                <MenuItem key={`category-${index}`} value={category.type_name}>
                  {category.type_name}
                </MenuItem>
              ))}
          </CustomSelect>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomSelect
            labelText="Status"
            id="status"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => updateField(event, "status"),
              value: formValues.status
            }}
          >
            <MenuItem value={"active"}>Active</MenuItem>
            <MenuItem value={"inactive"}>Not Active</MenuItem>
          </CustomSelect>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomDatePicker
            label={
              <Typography style={{ fontSize: "12px", color: "#AAAAAA" }}>
                From
              </Typography>
            }
            format={"yyyy/MM/dd"}
            selectedDate={formValues.created_from}
            onChange={e => onDatePickerChange(e, "created_from")}
          />
          <CustomDatePicker
            label={
              <Typography style={{ fontSize: "12px", color: "#AAAAAA" }}>
                To
              </Typography>
            }
            format={"yyyy/MM/dd"}
            selectedDate={formValues.created_to}
            onChange={e => onDatePickerChange(e, "created_to")}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomDatePicker
            label={
              <Typography style={{ fontSize: "12px", color: "#AAAAAA" }}>
                From
              </Typography>
            }
            format={"yyyy/MM/dd"}
            selectedDate={formValues.changed_from}
            onChange={e => onDatePickerChange(e, "changed_from")}
          />
          <CustomDatePicker
            label={
              <Typography style={{ fontSize: "12px", color: "#AAAAAA" }}>
                To
              </Typography>
            }
            format={"yyyy/MM/dd"}
            selectedDate={formValues.changed_to}
            onChange={e => onDatePickerChange(e, "changed_to")}
          />
        </GridItem>
      </div>
      <div className={classes.buttonsContainer}>
        <Button
          size={"sm"}
          color="info"
          round
          onClick={onFilterClick}
          className={classes.filterButton}
          disabled={!isButtonDisabled}
        >
          Filter
        </Button>
        <Button
          size={"sm"}
          color="info"
          round
          onClick={onResetFilterClick}
          className={classes.filterButton}
          disabled={!isButtonDisabled}
        >
          Reset
        </Button>
      </div>
    </React.Fragment>
  );
};

export default FIlterFieldsComponent;
