import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import CustomInput from "../CustomInput/CustomInput";
import Button from "../CustomButtons/Button";
import styles from "./styles/performerTableStyle";

const useStyles = makeStyles(styles);

const HeaderContentComponent = props => {
  const classes = useStyles();
  const { duration, onDaysChange, onFilterClick, isBtnDisabled, type } = props;

  return (
    <div className={classes.selectContainer}>
      <CustomInput
        labelText={"Days"}
        formControlProps={{
          fullWidth: true
        }}
        labelClass={classes.inputLabel}
        inputProps={{
          type: "number",
          onChange: e => onDaysChange(e.target.value, type),
          value: duration,
          name: type,
          required: true,
          style: { color: "white" }
        }}
      />
      <Button
        size={"sm"}
        color="white"
        round
        onClick={() => onFilterClick(type)}
        className={classes.filterButton}
        disabled={isBtnDisabled}
      >
        Submit
      </Button>
    </div>
  );
};

export default HeaderContentComponent;
