import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import styles from "./styles/customInputStyle";

const useStyles = makeStyles(styles);

const SuggestionsModal = props => {
  const classes = useStyles();
  const { suggestions, onSuggestionClick, isModalOpened } = props;

  const modalClasses = classNames({
    [classes.suggestinsModal]: true,
    [classes.suggestionsModalOpen]: isModalOpened,
    [classes.suggestionsModalClose]: !isModalOpened
  });

  return (
    <div className={modalClasses}>
      {suggestions && suggestions.length === 0 && <div>No results</div>}
      {suggestions &&
        suggestions.length > 0 &&
        isModalOpened &&
        suggestions.map((s, i) => {
          return (
            <span
              className={classes.span}
              onClick={() => onSuggestionClick(s)}
              key={i}
            >
              {s}
            </span>
          );
        })}
    </div>
  );
};

export default SuggestionsModal;
