const FeatureSearchService = {
  formatFeatureName(str) {
    const capitalized = str[0].toUpperCase() + str.slice(1);
    return capitalized.replace(/_/g, " ");
  },
  arrayChecker(arr) {
    return arr.every(Boolean);
  },
  checkNegativeValues(value) {
    return value < 0 ? 0 : value;
  },
  initStates: {
    featuresSearchInit: {
      lat: "",
      lng: "",
      city: "",
      zip: "",
      streetname: "",
      objectName: "Object B",
      isTyping: false
    },
    loadersInit: {
      featuresLoading: ""
    },
    resultsInit: {
      socialStatuses: {},
      typeSuggestions: [],
      featureMarkers: [],
      rectangleMarkers: []
    }
  },
  infoItems: [
    {
      id: 1,
      color: "red",
      title: "The first score (PLZ)",
      description:
        " describes your feature in relation to all areas that are included in the same PLZ like your searched object."
    },
    {
      id: 2,
      color: "green",
      title: "The second score (City)",
      description:
        " describes your feature in relation to all areas that are included in the same City like your searched object."
    },
    {
      id: 3,
      color: "yellow",
      title: "The third score (Deutschland)",
      description:
        " describes your feature in relation to all areas that are included in Germany."
    }
  ]
};

export default FeatureSearchService;
