import React from "react";

import Aux from "../../hoc/AuxReact";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import ProductTable from "../../components/ProductTable/ProductTable.component";

const ProductTableView = () => {
  return (
    <Aux>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <ProductTable />
        </GridItem>
      </GridContainer>
    </Aux>
  );
};

export default ProductTableView;
