import api from "../config/api";

const topGrowth = (page, rowsPerPage, key, sortDir, cityName) => {
  const params = {
    page,
    rowsPerPage
  };
  if (key !== "") params.sortBy = key;
  if (sortDir !== "") params.sortDir = sortDir;
  if (cityName !== "") params.name = cityName;

  return api.get("variance/top-growth", {
    params: {
      ...params
    }
  });
};

const resetPlaceGrowthRate = zip => {
  return api.post("variance/place-reset", {
    zip
  });
};

const zipcodeGrowthRate = (
  page,
  rowsPerPage,
  property = "Kaufen",
  typeName = null,
  params
) => {
  const {
    growthRate,
    radius,
    sizeFrom,
    sizeTo,
    priceFrom,
    priceTo,
    yearFrom,
    yearTo,
    rentalPercent,
    month,
    site,
    categoryValue
  } = params;
  return api.post("variance/zipcode-growth", {
    page,
    rowsPerPage,
    property,
    typeName,
    growthRate,
    radius,
    sizeFrom,
    sizeTo,
    priceFrom,
    priceTo,
    yearFrom,
    yearTo,
    rentalPercent,
    month,
    site,
    categoryValue
  });
};

export { topGrowth, resetPlaceGrowthRate, zipcodeGrowthRate };
