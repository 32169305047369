import React from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./arrowStyle";

const useStyles = makeStyles(styles);

function Arrow(props) {
  const classes = useStyles();

  return (
    <div
      className={`${classes.arrow} ${
        props.isToggled ? classes.closed : classes.opened
      }`}
      onClick={() => props.onToggleSidebar()}
    >
      {props.isToggled ? <ChevronRight /> : <ChevronLeft />}
    </div>
  );
}

export default Arrow;
