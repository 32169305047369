import api from "../config/api";

const ProductApi = {
  getTypes() {
    return api.get("types");
  },
  getGraphicsByMonth(
    lat,
    lng,
    address,
    typeName = null,
    zipcode = null,
    separateValue = 40,
    radius
  ) {
    return api.get("get-graphics", {
      params: {
        lat,
        lng,
        address,
        typeName,
        zipcode,
        separateValue,
        radius
      }
    });
  },
  show(propertyID) {
    return api.get(`product/${propertyID}`);
  }
};

export default ProductApi;
