import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import styles from "./styles/formStyle";
import GridContainer from "../Grid/GridContainer";
import CardBody from "../Card/CardBody";
import CustomSelect from "../CustomSelect/CustomSelect";
import { MenuItem } from "@material-ui/core";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import HighPerformerService from "../../services/HighPerformerService";

const useStyles = makeStyles(styles);

const Form = props => {
  const classes = useStyles();
  const {
    changeSearchParam,
    onSubmit,
    searchParams,
    categories,
    shortTermDataLoading,
    longTermDataLoading,
    shortTermRentDataLoading,
    shortTermBuyDataLoading
  } = props;
  const { category, state, year, month } = searchParams;

  const { germanyStatesList, years, months } = HighPerformerService;

  const onFormValueChange = (event, name) => {
    const { value } = event.target;
    changeSearchParam(name, value);
  };

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Suche</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              labelText="State"
              id="state"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => onFormValueChange(event, "state"),
                value: state
              }}
            >
              {germanyStatesList.length > 0 &&
                germanyStatesList.map((state, index) => (
                  <MenuItem key={`state-${index}`} value={state}>
                    {state}
                  </MenuItem>
                ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              labelText="Category"
              id="category"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => onFormValueChange(event, "category"),
                value: category
              }}
            >
              {categories.length > 0 &&
                categories.map((category, index) => (
                  <MenuItem
                    key={`category-${index}`}
                    value={category.type_name}
                  >
                    {category.type_name}
                  </MenuItem>
                ))}
            </CustomSelect>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              labelText="Year"
              id="performerYear"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => onFormValueChange(event, "year"),
                value: year
              }}
            >
              {years.map((year, index) => (
                <MenuItem key={`year-${index}`} value={year.value}>
                  {year.value}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomSelect
              labelText="Month"
              id="performerMonth"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => onFormValueChange(event, "month"),
                value: month
              }}
            >
              {months.map((month, index) => (
                <MenuItem key={`month-${index}`} value={month.value}>
                  {month.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Button
              onClick={onSubmit}
              color={"primary"}
              round
              disabled={
                shortTermDataLoading ||
                longTermDataLoading ||
                shortTermRentDataLoading ||
                shortTermBuyDataLoading
              }
            >
              Suchen
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default Form;
