import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import create from "react-form-validation-hoc";

import styles from "./styles/formStyle";

import { MenuItem } from "@material-ui/core";
// core components
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomInput from "../CustomInput/CustomInput";
import CustomSelect from "../CustomSelect/CustomSelect";
import Button from "../CustomButtons/Button";
import LocationSearch from "../Map/LocationSearch";
import { required } from "../../config/validator-rules";

const useStyles = makeStyles(styles);

const Form = props => {
  const classes = useStyles();

  const { FieldDecorator, errors } = props.validator;

  const {
    onChangeAddress,
    onSelectAddress,
    onChangeSearchParam,
    onSubmit,
    isLoadding
  } = props;

  const {
    address,
    radius,
    category,
    rentalPercent,
    sizeFrom,
    sizeTo,
    priceFrom,
    priceTo,
    yearFrom,
    yearTo,
    separateValue,
    categories
    // type
  } = props.params;

  let isFormValid = false;
  isFormValid =
    !!errors.address || !!errors.category || (address === "" || category === "")
      ? false
      : true;

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Suche</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={9}>
            <FieldDecorator
              name={"address"}
              value={address}
              rules={[required("address")]}
            />
            <LocationSearch
              address={address}
              onChange={onChangeAddress}
              onSelect={onSelectAddress}
              message={errors.address}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <CustomInput
              labelText="Umkreis, (km)"
              id="radius"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "number",
                onChange: event => onChangeSearchParam(event, "radius"),
                value: radius
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={9}>
            <FieldDecorator
              name="category"
              value={category}
              rules={[required("category")]}
            />
            <CustomSelect
              labelText="Category"
              id="category"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => onChangeSearchParam(event, "category"),
                value: category
              }}
              message={{ error: errors.category }}
            >
              {categories.length > 0 &&
                categories.map((category, index) => (
                  <MenuItem
                    key={`category-${index}`}
                    value={category.type_name}
                  >
                    {category.type_name}
                  </MenuItem>
                ))}
            </CustomSelect>
          </GridItem>
          {/*<GridItem xs={12} sm={12} md={9}>*/}
          {/*  <CustomSelect*/}
          {/*    labelText="Product Type"*/}
          {/*    id="productType"*/}
          {/*    formControlProps={{*/}
          {/*      fullWidth: true*/}
          {/*    }}*/}
          {/*    inputProps={{*/}
          {/*      onChange: event => onChangeSearchParam(event, "type"),*/}
          {/*      value: type*/}
          {/*    }}*/}
          {/*  >*/}

          {/*    <MenuItem value={"all"}>Alle</MenuItem>*/}
          {/*    <MenuItem value={"mieten"}>Mieten</MenuItem>*/}
          {/*    <MenuItem value={"kaufen"}>Kaufen</MenuItem>*/}
          {/*  </CustomSelect>*/}
          {/*</GridItem>*/}
          <GridItem xs={12} sm={12} md={3}>
            <CustomInput
              labelText="Mietrendite, (%)"
              id="rental-yield"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "number",
                onChange: event => onChangeSearchParam(event, "rentalPercent"),
                value: rentalPercent
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="size / from"
                  id="size-from"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event => onChangeSearchParam(event, "sizeFrom"),
                    value: sizeFrom
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="size / to"
                  id="size-to"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event => onChangeSearchParam(event, "sizeTo"),
                    value: sizeTo
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="price / from"
                  id="price-from"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event => onChangeSearchParam(event, "priceFrom"),
                    value: priceFrom
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="price / to"
                  id="price-to"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event => onChangeSearchParam(event, "priceTo"),
                    value: priceTo
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="year / from"
                  id="year-from"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event => onChangeSearchParam(event, "yearFrom"),
                    value: yearFrom
                  }}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  labelText="year / to"
                  id="year-to"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: event => onChangeSearchParam(event, "yearTo"),
                    value: yearTo
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <CustomInput
              labelText="Separate by (qm)"
              id="separateValue"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "number",
                onChange: event => onChangeSearchParam(event, "separateValue"),
                value: separateValue
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Button
              onClick={onSubmit}
              color={"primary"}
              round
              disabled={isLoadding || !isFormValid}
            >
              Suchen
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default create()(Form);
