import React, { Component } from "react";
import { connect } from "react-redux";

import * as actionCreator from "../../store/actions/index";

// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";

// components
import Tool from "../../components/Dashboard/Tool";
import Progress from "../../components/Dashboard/Progress";

import DashboardApi from "../../api/Dashboard";
import Aux from "../../hoc/AuxReact";
import CustomTable from "../../components/Dashboard/CustomTable";

class Dashboard extends Component {
  componentDidMount() {
    this.props.toggleLoading("loading", true);
    DashboardApi.get()
      .then(resp => {
        const data = resp.data.data;
        Object.keys(data).map(key => {
          const value = data[key];
          this.props.onSet(key, value);
          return true;
        });
      })
      .then(() => {
        this.props.toggleLoading("loading", false);
      })
      .catch(err => console.log(err));
  }

  render() {
    return (
      <Aux>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6}>
            <Tool
              title={"ANZAHL INSERATE GESAMT"}
              color={"warning"}
              icon={"content_copy"}
              value={this.props.totalProducts}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Tool
              title={"PLZ MAX WACHSTUMSRATE (30 TAGE)"}
              color={"success"}
              icon={"stars"}
              value={this.props.zipcodeValue}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Tool
              title={"NEUE INSERATE (24H)"}
              color={"danger"}
              icon={"schedule"}
              value={this.props.today}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <Tool
              title={"AKTIVE ALARME"}
              color={"primary"}
              icon={"notifications"}
              value={this.props.alarmCount}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Progress
              saleValue={this.props.sale}
              rentValue={this.props.rent}
              color={"info"}
              title={"PROZENTVERTEILUNG INSERATE"}
              salePercentage={
                this.props.all !== 0
                  ? Math.round((this.props.sale / this.props.all) * 100)
                  : 0
              }
              rentPercentage={
                this.props.all !== 0
                  ? Math.round((this.props.rent / this.props.all) * 100)
                  : 0
              }
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomTable />
          </GridItem>
        </GridContainer>
      </Aux>
    );
  }
}

const mapStateToProp = state => {
  return {
    totalProducts: state.dashboard.totalProducts,
    zipcodeValue: state.dashboard.zipcodeValue,
    today: state.dashboard.today,
    alarmCount: state.dashboard.alarmCount,
    rent: state.dashboard.rent,
    sale: state.dashboard.sale,
    all: state.dashboard.all
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSet: (property, value) =>
      dispatch(actionCreator.dashboardSet(property, value)),
    toggleLoading: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value))
  };
};

export default connect(
  mapStateToProp,
  mapDispatchToProps
)(Dashboard);
