import { infoColor, dangerColor } from "assets/jss/material-dashboard-react";

const linkStyle = {
  root: {
    display: "inline"
  },
  link: {
    padding: "6px 12px",
    margin: "0 6px",
    borderRadius: "4px",
    color: infoColor[0],
    textAlign: "center",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid " + infoColor[0],
      color: infoColor[0]
    }
  },
  danger: {
    color: dangerColor[0],
    "&:hover": {
      border: "1px solid " + dangerColor[0],
      color: dangerColor[0]
    }
  },
  info: {
    color: infoColor[0],
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid " + infoColor[0],
      color: infoColor[0]
    }
  }
};

export default linkStyle;
