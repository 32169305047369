import * as actionTypes from "../actions/actionTypes";
import ProductTableApi from "../../api/ProductTableApi";

const getProdutTableStart = () => {
  return {
    type: actionTypes.GET_PRODUCT_TABLE_LIST_START
  };
};

const getProdutTableSuccess = produts => {
  return {
    type: actionTypes.GET_PRODUCT_TABLE_LIST_SUCCESS,
    payload: produts
  };
};

const getProductTableFailure = errorMessage => {
  return {
    type: actionTypes.GET_PRODUCT_TABLE_LIST_FAILURE,
    payload: errorMessage
  };
};

export const getProductTable = (
  params,
  rowsPerPage,
  page,
  key,
  sortDir
) => async dispatch => {
  dispatch(getProdutTableStart());

  try {
    const response = await ProductTableApi.getProductList(
      params,
      rowsPerPage,
      page,
      key,
      sortDir
    );
    const data = response.data.data;
    dispatch(getProdutTableSuccess(data));
  } catch (error) {
    dispatch(getProductTableFailure(error.message));
  }
};
