import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../unitily";

const initialState = {
  email: "",
  password: "",
  token: null,
  error: null,
  validationError: null,
  isWrongCredentials: false,
  loading: false
};

const authStart = (state = initialState, action) => {
  return updateObject(state, {
    validationError: null,
    error: null,
    token: action.token,
    loading: true
  });
};

const authSuccess = (state, action) => {
  window.localStorage.setItem("token", action.token);
  window.location.replace("/");

  return updateObject(state, {
    token: action.token,
    validationError: null,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    validationError: null,
    error: "Wrong email or password",
    isWrongCredentials: true,
    loading: false
  });
};

const changePropery = (state, action) => {
  const updState = { ...state };
  updState[action.payload.property] = action.payload.value;

  return {
    ...state,
    ...updState
  };
};

const authLogout = state => {
  return updateObject(state, {
    token: null,
    validationError: null,
    error: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.PROPERTY_CHANGE:
      return changePropery(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);
    default:
      return state;
  }
};

export default reducer;
