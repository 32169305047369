import React, { Component } from "react";
import { connect } from "react-redux";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import * as actionCreator from "../../store/actions/index";

import Aux from "../../hoc/AuxReact";

// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";

import Form from "../../components/FeatureSearch/Form";
import ResultsContainer from "../../components/FeatureSearch/ResultsContainer";
import { withStyles } from "@material-ui/core/styles";
import FeatureSearchApi from "../../api/FeatureSearch";
import FeatureSearchService from "../../services/FeatureSearchService";
import styles from "./featureSearchStyle";

class FeatureSearch extends Component {
  componentWillUnmount() {
    const { featuresSearch } = this.props;
    featuresSearch.forEach((param, index) => {
      this.props.setFeaturesSearchNestedParam("isTyping", false, index);
    });
  }

  handleCheckboxes = e => {
    const name = e.target.name;
    const isChecked = e.target.checked;
    const { checkBoxes } = this.props;
    checkBoxes.forEach(chb => {
      if (chb.name === name) chb.isChecked = isChecked;
    });
    this.props.setFeaturesSearchParam("checkBoxes", checkBoxes);
  };

  handleScreenSplit = e => {
    const {
      featuresSearchInit,
      loadersInit,
      resultsInit
    } = FeatureSearchService.initStates;
    let featuresSearch = this.props.featuresSearch;
    let loaders = this.props.loaders;
    let results = this.props.results;

    if (e.target.checked) {
      featuresSearch.push(featuresSearchInit);
      loaders.push(loadersInit);
      results.push(resultsInit);
    } else {
      featuresSearch.pop();
      loaders.pop();
      results.pop();
    }
    this.props.setFeaturesSearchParam(
      "isFetureSearchSplitted",
      e.target.checked
    ); //split screen checkbox
    this.props.setFeaturesSearchParam("featuresSearch", featuresSearch);
    this.props.toggleLoadings(loaders);
  };

  handleAllChecked = e => {
    const { checkBoxes } = this.props;
    checkBoxes.forEach(chb => (chb.isChecked = e.target.checked));
    this.props.setFeaturesSearchParam("checkBoxes", checkBoxes);
  };

  onParamChange = (type, value, index) => {
    const { city, zip } = this.props.featuresSearch[index];
    this.props.setFeaturesSearchNestedParam(type, value, index);
    this.props.setFeaturesSearchNestedParam("isTyping", true, index);

    if (value.length === 0) {
      this.props.setFeaturesSearchResults("typeSuggestions", [], index);
    }
    this.props.setFeaturesSearchResults("typeSuggestions", [], index);

    if (value.length > 0 && type !== "housenumber") {
      FeatureSearchApi.getSuggestions({
        type,
        value,
        city,
        zip
      })
        .then(resp => {
          const data = resp.data.data;
          const suggestions = { [type]: [] };

          data.forEach(d => {
            if (type === "streetname") {
              suggestions[type].push(d.full_street_name);
            }
            if (type === "zip") {
              suggestions[type].push(d.zip_city);
            }
            if (type === "city") {
              suggestions[type].push(d.city);
            }
          });
          if (data.length === 0) {
            this.props.setFeaturesSearchResults("typeSuggestions", [], index);
          }

          this.props.setFeaturesSearchResults(
            "typeSuggestions",
            suggestions,
            index
          );

          this.props.setFeaturesSearchParam("suggestionsObject", data);
        })
        .catch(() => null);
    }
  };

  onSuggestionClick = (type, suggestion, index) => {
    const { suggestionsObject } = this.props;
    let zip = "";

    if (type === "streetname") {
      suggestionsObject.forEach(sug => {
        if (sug.full_street_name === suggestion) {
          this.props.setFeaturesSearchNestedParam(
            "streetname",
            sug.streetname,
            index
          );
          zip = sug.zip;
          this.props.setFeaturesSearchNestedParam("zip", sug.zip, index);
          this.props.setFeaturesSearchNestedParam("city", sug.city, index);
        }
      });
    }
    if (type === "zip") {
      this.props.setFeaturesSearchNestedParam("isTyping", false, index);
      suggestionsObject.forEach(sug => {
        if (sug.zip_city === suggestion) {
          zip = sug.zip;
          this.props.setFeaturesSearchNestedParam("city", sug.city, index);
        }
      });
    }

    if (type !== "streetname") {
      this.props.setFeaturesSearchNestedParam(type, suggestion, index);
    }

    this.props.setFeaturesSearchNestedParam("zip", zip, index);
    this.props.setFeaturesSearchNestedParam("isTyping", false, index);
    this.props.setFeaturesSearchResults("typeSuggestions", [], index);
  };

  onSubmitHandler = index => {
    const { featuresSearch } = this.props;
    this.props.setFeaturesSearchResults("typeSuggestions", [], index);
    let responseError = [];
    this.props.setFeaturesSearchResults("socialStatuses", {}, index);

    for (let index = 0; index < featuresSearch.length; index++) {
      this.props.toggleDynamicLoaders("featuresLoading", true, index);

      FeatureSearchApi.getFeaturesStatuses(featuresSearch[index])
        .then(res => {
          responseError[index] = null;
          this.props.setFeaturesSearchParam("responseError", responseError);
          if (res === true) {
            this.props.toggleDynamicLoaders("featuresLoading", false, index);
            responseError[
              index
            ] = `No results found for ${featuresSearch[index].city}!`;
            this.props.setFeaturesSearchParam("responseError", responseError);
          }
          return res;
        })
        .then(res => {
          const data = res.data.data;
          responseError[index] = "";
          this.props.setFeaturesSearchParam("responseError", responseError);

          let centerLat =
            (data.rectangle[1].product_latitude +
              data.rectangle[3].product_latitude) /
            2;
          let centerLng =
            (data.rectangle[1].product_longitude +
              data.rectangle[3].product_longitude) /
            2;
          this.props.setFeaturesSearchNestedParam("lat", centerLat, index);
          this.props.setFeaturesSearchNestedParam("lng", centerLng, index);
          this.props.setFeaturesSearchResults(
            "featureMarkers",
            data.markers,
            index
          );
          this.props.setFeaturesSearchResults(
            "rectangleMarkers",
            data.rectangle,
            index
          );
          let chb = [];
          Object.keys(data.features).forEach(s => {
            chb.push({ name: s, isChecked: true });
          });
          this.props.setFeaturesSearchParam("checkBoxes", chb);
          this.props.setFeaturesSearchResults(
            "socialStatuses",
            data.features,
            index
          );
          this.props.toggleDynamicLoaders("featuresLoading", false, index);
        })
        .catch(error => console.log(error));
    }
    this.props.setFeaturesSearchParam("responseError", responseError);
  };

  render() {
    const {
      results,
      loaders,
      featuresSearch,
      classes,
      checkBoxes,
      responseError,
      isFetureSearchSplitted
    } = this.props;

    return (
      <Aux>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.splitResultsContainer}
          >
            {featuresSearch.map((f, i) => {
              return (
                <Form
                  onSubmit={this.onSubmitHandler}
                  onChangeSearchParam={this.onParamChange}
                  isLoadding={loaders[i].featuresLoading}
                  params={{ featuresSearch: featuresSearch, index: i }}
                  suggestions={results[i].typeSuggestions}
                  onSuggestionClick={this.onSuggestionClick}
                  isSuggestionsModal={featuresSearch[i].isTyping}
                  objectName={f.objectName}
                  key={i}
                />
              );
            })}
          </GridItem>
          <GridItem xs={12} sm={12} md={9} lg={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isFetureSearchSplitted}
                  onChange={this.handleScreenSplit}
                  name="isFetureSearchSplitted"
                  color="primary"
                />
              }
              label="Split Screen"
            />
          </GridItem>
          {responseError.length > 0 &&
            responseError.map((error, i) => (
              <div className={classes.errorWrapper} key={i}>
                <h4>{error}</h4>
              </div>
            ))}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <ResultsContainer
              loaders={loaders}
              results={results}
              responseError={responseError}
              featuresSearch={featuresSearch}
              checkBoxes={checkBoxes}
              handleAllChecked={this.handleAllChecked}
              handleCheckboxes={this.handleCheckboxes}
              isFetureSearchSplitted={isFetureSearchSplitted}
            />
          </GridItem>
        </GridContainer>
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  const { ui, featuresSearch } = state;

  return {
    searchFormParams: {
      lat: featuresSearch.lat,
      lng: featuresSearch.lng,
      city: featuresSearch.city,
      zip: featuresSearch.zip,
      streetname: featuresSearch.streetname
    },
    results: featuresSearch.featuresSearchResults,
    loaders: ui.loaders,
    featuresSearch: featuresSearch.featuresSearch,
    checkBoxes: featuresSearch.checkBoxes,
    responseError: featuresSearch.responseError,
    isFetureSearchSplitted: featuresSearch.isFetureSearchSplitted,
    suggestionsObject: featuresSearch.suggestionsObject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleLoadings: value => dispatch(actionCreator.toggleMultiple(value)),
    toggleDynamicLoaders: (property, value, index) =>
      dispatch(actionCreator.toggleDynamicLoaders(property, value, index)),
    setFeaturesSearchParam: (property, value) =>
      dispatch(actionCreator.setFeaturesSearchParam(property, value)),
    setFeaturesSearchNestedParam: (property, value, index) =>
      dispatch(
        actionCreator.setFeaturesSearchNestedParam(property, value, index)
      ),
    setFeaturesSearchResults: (property, value, index) =>
      dispatch(actionCreator.setFeaturesSearchResults(property, value, index))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FeatureSearch));
