import axios from "axios";

const token = window.localStorage.getItem("token");

const config = {
  baseURL: `https://ai.immowin24.de/api/`,
  headers: {
    ContentType: "application/json",
    Accept: "*/*"
  }
};

if (token) {
  config.headers["X-Authorization"] = `Bearer ${token}`;
}

if (window.location.hostname === "localhost") {
  config.baseURL = "https://ai.immowin24.de/api/";
}

if (window.location.hostname === "fcrimmo.testinghub.xyz") {
  config.baseURL = "http://54.171.232.23/api/";
}

const instance = axios.create(config);

const logout = () => {
  removeToken();
  window.location.replace("/auth/login");
};
const saveToken = token => {
  window.localStorage.setItem("token", token);
};
const removeToken = () => {
  window.localStorage.removeItem("token");
};

const refreshToken = () => {
  return instance.post("/refresh");
};

instance.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    switch (error.response.data.status) {
      case "Token is Expired": {
        refreshToken()
          .then(resp => {
            const token = resp.data.access_token;

            removeToken();
            saveToken(token);

            window.location.reload();
          })
          .catch(() => {
            logout();
            return Promise.reject(error);
          });

        break;
      }
      case "Token is Invalid": {
        logout();

        break;
      }
      case "Authorization Token not found": {
        logout();

        break;
      }
      default: {
        return true;
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
