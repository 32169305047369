export const worldMapCoordinates = [
  [-85.1054596961173, -180],
  [85.1054596961173, -180],
  [85.1054596961173, 180],
  [-85.1054596961173, 180],
  [-85.1054596961173, 0]
];

export const makeCustomZoom = (radius, defaultZoom) => {
  return parseInt(radius) === 1
    ? 16
    : parseInt(radius) === 2
    ? 15
    : parseInt(radius) === 3
    ? 14
    : parseInt(radius) === 4
    ? 13
    : parseInt(radius) === 5
    ? 13
    : parseInt(radius) === 6
    ? 13
    : defaultZoom;
};
