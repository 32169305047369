import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./styles/suggestionsStyle";

const useStyles = makeStyles(styles);

const Suggestions = props => {
  const classes = useStyles();
  const { getSuggestionItemProps, loading, suggestions } = props;

  return (
    <div className={classes.wrapper}>
      {loading && <div>Loading...</div>}
      {suggestions.map((suggestion, index) => {
        return (
          <div
            key={`suggestion-${index}`}
            {...getSuggestionItemProps(suggestion)}
            className={classes.item}
          >
            {suggestion.description}
          </div>
        );
      })}
    </div>
  );
};

export default Suggestions;
