import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";
import styles from "./loaderStyle";

const useStyles = makeStyles(styles);

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.blur} />
      <CircularProgress
        classes={{
          root: classes.root,
          colorPrimary: classes.colorPrimary
        }}
        color={"primary"}
      />
    </div>
  );
};

export default Loader;
