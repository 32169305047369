import * as actionTypes from "./actionTypes";
import AuthService from "../../services/AuthService";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = token => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};

export const authLogout = () => {
  window.localStorage.removeItem("token");
};

export const authFail = error => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const authAction = (email, password) => {
  return dispatch => {
    dispatch(authStart());

    AuthService.login(email, password)
      .then(resp => {
        dispatch(authSuccess(resp.data.access_token));
      })
      .catch(error => {
        dispatch(authFail(error));
      });
  };
};

export const changeProperty = (property, value) => {
  return {
    type: actionTypes.PROPERTY_CHANGE,
    payload: {
      property,
      value
    }
  };
};
