import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Chart from "./Chart";
import GridItem from "../Grid/GridItem";
import Backdrop from "../../ui/Backdrop/Backdrop";
import styles from "./styles/chartStyle";

const useStyles = makeStyles(styles);

const SelectedChart = props => {
  const classes = useStyles();
  const { onChartClose, params, isSidebarToggled } = props;
  const { type, color, data, title, subtitle, message, subText } = params;

  const selectedChartClasses = classNames({
    [classes.chartModal]: true
  });

  return (
    <Backdrop isSidebarToggled={isSidebarToggled}>
      <GridItem xs={12} sm={12} md={8} lg={8} className={selectedChartClasses}>
        <Chart
          type={type}
          color={color}
          data={data}
          title={title}
          subtitle={subtitle}
          message={message}
          subText={subText}
        />
        <span className={classes.closeIcon} onClick={onChartClose}>
          X
        </span>
      </GridItem>
    </Backdrop>
  );
};

export default SelectedChart;
