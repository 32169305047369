import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

// core components
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardFooter from "../Card/CardFooter";
import CardIcon from "../Card/CardIcon";

import styles from "./styles/dashboardStyle.js";

const useStyles = makeStyles(styles);

const Tool = props => {
  const classes = useStyles();
  const { icon, title, value, color } = props;

  return (
    <Card>
      <CardHeader color={color} stats icon>
        <CardIcon color={color}>
          <Icon>{icon}</Icon>
        </CardIcon>
        <h3 className={classes.cardTitle}>{value}</h3>
      </CardHeader>
      <CardFooter stats>
        <div className={classes.stats}>
          <p>{title}</p>
        </div>
      </CardFooter>
    </Card>
  );
};

export default Tool;
