import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import TableCell from "@material-ui/core/TableCell";
import { Launch } from "@material-ui/icons";
// core components
import styles from "./tableStyle.js";
import Avatar from "../Avatar/SqrtAvatar";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    onChangeOrder,
    tableDataWithIcon,
    isClickable,
    onCellClick,
    clickableKey,
    orderBy,
    order
  } = props;

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                    onClick={() =>
                      onChangeOrder ? onChangeOrder(prop.key) : () => false
                    }
                    style={
                      prop.key
                        ? {
                            cursor: "pointer"
                          }
                        : {}
                    }
                  >
                    {prop.key ? (
                      <TableSortLabel
                        active={true}
                        direction={orderBy === prop.key ? order : "asc"}
                      >
                        {prop.label || prop}
                      </TableSortLabel>
                    ) : (
                      prop.label || prop
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData &&
            tableData.length > 0 &&
            tableData.map((prop, key) => {
              return (
                <TableRow key={key} className={classes.tableBodyRow}>
                  {prop.map((prop, key) => {
                    const label =
                      key === clickableKey && isClickable ? (
                        <span
                          onClick={() =>
                            isClickable ? onCellClick(prop.label) : () => false
                          }
                          className={classes.cellLink}
                        >
                          {prop.label}
                        </span>
                      ) : (
                        prop.label
                      );
                    return (
                      <TableCell className={classes.tableCell} key={key}>
                        {prop !== undefined &&
                        prop.url &&
                        typeof prop.action !== "function" ? (
                          <a href={prop.url} target={"_blank"}>
                            {prop.label}
                          </a>
                        ) : (
                          ""
                        )}
                        {prop !== undefined && prop.urlComesFrom ? (
                          <a href={prop.urlComesFrom} target={"_blank"}>
                            <Launch
                              style={{
                                fontSize: "1rem"
                              }}
                            />
                          </a>
                        ) : (
                          ""
                        )}
                        {prop !== undefined &&
                        prop.components &&
                        typeof prop.action === "function" ? (
                          <a
                            href={"/"}
                            onClick={event => {
                              event.preventDefault();
                              prop.action(prop.name);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {prop.label}
                          </a>
                        ) : (
                          label
                        )}
                        {prop !== undefined &&
                          prop.components !== undefined &&
                          prop.components.map(component => component)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          {tableDataWithIcon &&
            tableDataWithIcon.length > 0 &&
            tableDataWithIcon.map((item, key) => {
              return (
                <TableRow key={`icon-${key}`} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell}>
                    <Avatar
                      src={item.image || item.image_url}
                      alt={item.title}
                      size={"large"}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <a href={item.url || item.link} target={"_blank"}>
                      {item.title}
                    </a>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.description}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item.publishedAt}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  tableData: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  )
};
