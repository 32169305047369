import React from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
  Polygon
} from "react-google-maps";
import { compose, withProps, withHandlers } from "recompose";

import { map } from "../../config/google";
import InfoWindowMap from "./InfoWindowMap";
import { makeCustomZoom } from "./constants";

const SearchSkinMap = compose(
  withProps({
    googleMapURL: map.url,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withHandlers(() => {
    const refs = {
      map: undefined
    };

    return {
      onMapMounted: () => ref => {
        refs.map = ref;
      },
      onVisibleChanged: props => () => {
        if (props.onIdle !== undefined) {
          props.onIdle(refs.map.getBounds());
        }
      }
    };
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const {
    markers,
    onVisibleChanged,
    onMapMounted,
    polyline,
    myZoom,
    onWindowClick,
    isInfoWindow,
    selectedMarkers
  } = props;
  let shape = props.shape;

  let poligon;
  if (shape) {
    shape = shape[1] !== undefined ? shape : shape[0];
    poligon = shape.map(d => ({
      lng: d[0],
      lat: d[1]
    }));
  }

  let polylinePath;
  if (polyline) {
    polylinePath = polyline.map(item => {
      return {
        lng: item.product_longitude,
        lat: item.product_latitude
      };
    });
  }

  const customZoom = makeCustomZoom(props.radius, map.defaultZoom);

  return (
    <GoogleMap
      ref={onMapMounted}
      defaultZoom={myZoom || customZoom}
      defaultCenter={props.center}
      defaultOptions={map.defaultOptions}
      onVisibleChanged={typeIds => onVisibleChanged(typeIds)}
      onTilesLoaded={typeIds => onVisibleChanged(typeIds)}
    >
      {markers &&
        markers.map((marker, index) => {
          return (
            <InfoWindowMap
              key={`marker-${index}`}
              index={index}
              item={marker}
              lat={marker.product_latitude}
              lng={marker.product_longitude}
              onWindowClick={onWindowClick ? onWindowClick : () => false}
              isInfoWindow={isInfoWindow}
              selectedMarkers={selectedMarkers}
              markers={markers}
            />
          );
        })}
      {shape ? (
        <Polygon
          paths={poligon}
          center={props.center}
          options={{
            fillColor: "#FF0000",
            strokeColor: "#FF0000",
            strokeWeight: 1,
            fillOpacity: 0.1
          }}
        />
      ) : (
        ""
      )}

      {shape !== undefined && !shape ? (
        <Circle
          radius={parseInt(props.radius) * 1000}
          center={props.center}
          options={{
            fillColor: "#FF0000",
            strokeColor: "#FF0000",
            strokeWeight: 1,
            fillOpacity: 0.1
          }}
        />
      ) : (
        ""
      )}

      {polyline && (
        <Polygon
          path={polylinePath}
          options={{
            fillColor: "#FF0000",
            strokeColor: "#FF0000",
            strokeWeight: 1,
            fillOpacity: 0.1
          }}
        />
      )}
    </GoogleMap>
  );
});
export default SearchSkinMap;
