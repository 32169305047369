import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./collapseSwitcherStyle";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles(styles);

const Switcher = props => {
  const classes = useStyles();
  const { isOpen, title, onClick } = props;

  return (
    <div className={classes.root} onClick={onClick}>
      <p>{title}</p>
      {isOpen ? (
        <ExpandLess
          classes={{
            root: classes.icon
          }}
        />
      ) : (
        <ExpandMore
          classes={{
            root: classes.icon
          }}
        />
      )}
    </div>
  );
};

export default Switcher;
