import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import styles from "./styles/features";

const useStyles = makeStyles(styles);

const InfoTextComponent = props => {
  const classes = useStyles();
  const { infoItems } = props;

  return (
    <Card>
      <CardBody>
        {infoItems.map(item => (
          <div className={classes.featuresText} key={item.id}>
            <div className={classes[item.color]} />
            &nbsp;
            <div>
              <b>{item.title}</b> <span>{item.description}</span>
            </div>
          </div>
        ))}
      </CardBody>
    </Card>
  );
};

export default InfoTextComponent;
