import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import classNames from "classnames";

// core components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import styles from "./styles/features";
import InfoTextComponent from "./InfoText.component";
import FeatureSearchService from "../../services/FeatureSearchService";

const useStyles = makeStyles(styles);

const FeaturesCard = props => {
  const classes = useStyles();
  const {
    color,
    checkedItems,
    results,
    featuresSearchParams,
    isFetureSearchSplitted,
    responseError
  } = props;
  const { checkNegativeValues, infoItems, arrayChecker } = FeatureSearchService;
  const isDescriptionBlock = arrayChecker(responseError);

  const infoTextClasses = classNames({
    [classes.visibleInfoTextBlock]: !isDescriptionBlock,
    [classes.inVisibleInfoTextBlock]: isDescriptionBlock
  });

  let centrality = [];
  let social_status = [];
  let young_population = [];
  let urban = [];

  results.forEach((res, i) => {
    if (res.socialStatuses.centrality !== undefined) {
      centrality.push(res.socialStatuses.centrality);
    }
    if (res.socialStatuses.social_status !== undefined) {
      social_status.push(res.socialStatuses.social_status);
    }
    if (res.socialStatuses.young_population !== undefined) {
      young_population.push(res.socialStatuses.young_population);
    }
    if (res.socialStatuses.urban !== undefined) {
      urban.push(res.socialStatuses.urban);
    }
  });

  const deviceSize = isFetureSearchSplitted ? 12 : 10;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={deviceSize} lg={deviceSize} className={infoTextClasses}>
        <InfoTextComponent infoItems={infoItems} />
      </GridItem>
      <GridItem xs={12} sm={12} md={deviceSize} lg={deviceSize}>
        <div className={classes.featuresWrapper}>
          {centrality.length > 0 &&
            checkedItems[0].isChecked &&
            centrality.map((item, i) => {
              return (
                <GridItem xs={12} sm={6} md={6} key={i}>
                  <Card>
                    <CardHeader color={color} stats>
                      Centrality - {featuresSearchParams[i].objectName}
                    </CardHeader>
                    <React.Fragment>
                      <CardBody>
                        <h4 className={classes.cardTitle}>PLZ</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.plz)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.plzColorPrimary,
                            barColorPrimary: classes.plzBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.plz)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Stadt</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.city)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.cityColorPrimary,
                            barColorPrimary: classes.cityBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.city)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Deutschland</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.country)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.countryColorPrimary,
                            barColorPrimary: classes.countryBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.country)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                    </React.Fragment>
                    <p className={classes.infoText}>
                      <b>Centrality</b> describes the nearness to the center of
                      your choosen city/location. A score of 100 means its very
                      near to the city center, 0 would describe a object at the
                      edges of the city.
                    </p>
                  </Card>
                </GridItem>
              );
            })}
          {social_status.length > 0 &&
            checkedItems[1].isChecked &&
            social_status.map((item, i) => {
              return (
                <GridItem xs={12} sm={6} md={6} key={i}>
                  <Card>
                    <CardHeader color={color} stats>
                      Social Status - {featuresSearchParams[i].objectName}
                    </CardHeader>
                    <React.Fragment>
                      <CardBody>
                        <h4 className={classes.cardTitle}>PLZ</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.plz)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.plzColorPrimary,
                            barColorPrimary: classes.plzBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.plz)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Stadt</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.city)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.cityColorPrimary,
                            barColorPrimary: classes.cityBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.city)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Deutschland</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.country)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.countryColorPrimary,
                            barColorPrimary: classes.countryBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.country)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                    </React.Fragment>
                    <p className={classes.infoText}>
                      <b>Social Status</b> describes the social status of the
                      population in this area. A score of 100 reflects that the
                      searched property is in an area with High Class
                      Population. The score 0 would reflect the lower class.
                    </p>
                  </Card>
                </GridItem>
              );
            })}
          {young_population.length > 0 &&
            checkedItems[2].isChecked &&
            young_population.map((item, i) => {
              return (
                <GridItem xs={12} sm={6} md={6} key={i}>
                  <Card>
                    <CardHeader color={color} stats>
                      Young Population - {featuresSearchParams[i].objectName}
                    </CardHeader>
                    <React.Fragment>
                      <CardBody>
                        <h4 className={classes.cardTitle}>PLZ</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.plz)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.plzColorPrimary,
                            barColorPrimary: classes.plzBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.plz)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Stadt</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.city)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.cityColorPrimary,
                            barColorPrimary: classes.cityBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.city)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Deutschland</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.country)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.countryColorPrimary,
                            barColorPrimary: classes.countryBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.country)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                    </React.Fragment>
                    <p className={classes.infoText}>
                      <b>Young Populationn</b> describes how many young people
                      live in this area in relation to the average age.
                    </p>
                  </Card>
                </GridItem>
              );
            })}
          {urban.length > 0 &&
            checkedItems[3].isChecked &&
            urban.map((item, i) => {
              return (
                <GridItem xs={12} sm={6} md={6} key={i}>
                  <Card>
                    <CardHeader color={color} stats>
                      Urban - {featuresSearchParams[i].objectName}
                    </CardHeader>
                    <React.Fragment>
                      <CardBody>
                        <h4 className={classes.cardTitle}>PLZ</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.plz)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.plzColorPrimary,
                            barColorPrimary: classes.plzBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.plz)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Stadt</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.city)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.cityColorPrimary,
                            barColorPrimary: classes.cityBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.city)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                      <CardBody>
                        <h4 className={classes.cardTitle}>Deutschland</h4>
                        <div className={classes.progressHeader}>
                          <p />
                          <p>{checkNegativeValues(item.country)}</p>
                        </div>
                        <LinearProgress
                          classes={{
                            colorPrimary: classes.countryColorPrimary,
                            barColorPrimary: classes.countryBarColorPrimary
                          }}
                          variant="determinate"
                          value={checkNegativeValues(item.country)}
                        />
                        <div className={classes.progressHeader}>
                          <p>0</p>
                          <p>100</p>
                        </div>
                      </CardBody>
                    </React.Fragment>
                    <p className={classes.infoText}>
                      <b>Urban</b> describes the lifestyle, multiculturality of
                      the area, and nearness to Restaurants and co. A score of
                      100 reflects that its a very open world and multicultural
                      area, 0 instead is very conservative and non-multicultural
                      area
                    </p>
                  </Card>
                </GridItem>
              );
            })}
        </div>
      </GridItem>
    </GridContainer>
  );
};

export default FeaturesCard;
