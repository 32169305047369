import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { geocodeByAddress } from "react-places-autocomplete";
import { makeStyles } from "@material-ui/core/styles";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Table from "../Table/Table";
import CardFooter from "../Card/CardFooter";
import TablePaginationActions from "../PaginatorActions/PaginatorActions";

import { TablePagination } from "@material-ui/core";

import * as VarianceAPI from "../../api/Variance";
import * as actionCreator from "../../store/actions";
import CustomInput from "../CustomInput/CustomInput";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import styles from "./styles/growthRateStyles";

const useStyles = makeStyles(styles);

const GrowthRate = props => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [cityName, changeCityName] = useState("");
  const [dirBoolean, toggleSortDirBoolean] = useState(true);
  const [sortDir, toggleSortDir] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const data = [];
  const { set, toggleLoadding, changeSearchParam, category } = props;
  let isButtonDisabled = cityName === "";

  const requestHandler = useCallback(
    data => {
      set({
        topGrowth: data.data
      });
      setCount(data.total);
      toggleLoadding("topGrowth", false);
    },
    [set, toggleLoadding]
  );

  const onTableUpdate = useCallback(
    (page, rowsPerPage, key, sortDir, name) => {
      toggleLoadding("topGrowth", true);

      sendRequest(page, rowsPerPage, key, sortDir, name)
        .then(resp => resp.data.data)
        .then(data => requestHandler(data));
    },
    [toggleLoadding, requestHandler]
  );

  useEffect(() => {
    onTableUpdate(page, rowsPerPage);
    // eslint-disable-next-line
  }, [ page, rowsPerPage]);

  // eslint-disable-next-line
  useEffect(() => {
    let dir = "";
    if (dirBoolean === true) {
      dir = "asc";
    }
    if (dirBoolean === false) {
      dir = "desc";
    }
    toggleSortDir(dir);
  });

  const sendRequest = (page, rowsPerPage, key, sortDir, name) => {
    return VarianceAPI.topGrowth(page + 1, rowsPerPage, key, sortDir, name);
  };

  const onChangePageHandler = page => {
    setPage(page);
  };

  const onChangeRowPerPagaHandler = rPerPage => {
    setPage(0);
    setRowsPerPage(rPerPage);
  };

  props.topGrowth.map((item, index) => {
    const name = {
      label: item.name,
      key: "product_city"
    };
    const state = {
      label: item.state,
      key: "product_state"
    };
    const variance = {
      label: `${item.variance} %`
    };

    const sale_price = {
      label:
        item.avg_sale_price !== 0 || item.avg_sale_price !== null
          ? item.avg_sale_price
          : 0
    };

    if (data[index] === undefined) {
      data[index] = [];
    }

    return data[index].push(name, state, variance, sale_price);
  });

  const onAddressClick = address => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    props.setSingle("totalResults", "");

    geocodeByAddress(`${address} + Germany`).then(data => {
      props.history.push(`/admin/search`);

      url.searchParams.set("address", data[0].formatted_address);
      url.searchParams.set("distance", 0);
      url.searchParams.set("type_name", category);

      changeSearchParam("address", data[0].formatted_address);
      changeSearchParam("radius", 0);
      changeSearchParam("category", category);

      const newUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?${url.search.slice(1)}`;

      window.history.pushState({ path: newUrl }, "", newUrl);
    });
  };

  const onChangeHandler = e => {
    const value = e.target.value;
    changeCityName(value);
    if (value === "") {
      onTableUpdate(page, rowsPerPage);
    }
  };

  const onChangeOrder = key => {
    if (key !== undefined) {
      toggleSortDirBoolean(prevState => !prevState);
      onTableUpdate(page, rowsPerPage, key, sortDir, cityName);
      setOrderBy(key);
    }
  };

  const onFilterClick = () => {
    onTableUpdate(page, rowsPerPage, "", "", cityName);
  };

  return (
    <Card>
      <CardHeader color={"info"}>
        Top rate growth zip / city (30 days)
      </CardHeader>
      <div className={classes.fieldContainer}>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText={"Please enter the city name"}
            id={"cityName"}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "text",
              onChange: event => onChangeHandler(event),
              value: cityName
            }}
          />
        </GridItem>
        <Button
          size={"sm"}
          color="primary"
          round
          onClick={onFilterClick}
          className={classes.filterButton}
          disabled={isButtonDisabled}
        >
          Filter
        </Button>
      </div>
      <CardBody>
        <Table
          tableHeaderColor="info"
          tableData={data}
          isClickable={true}
          onCellClick={onAddressClick}
          clickableKey={0}
          onChangeOrder={onChangeOrder}
          order={sortDir}
          orderBy={orderBy}
          tableHead={[
            {
              label: "Place name"
            },
            {
              label: "State name"
            },
            {
              label: "Variance",
              key: "variance"
            },
            {
              label: "M² Price (€)",
              key: "avg_sale_price"
            }
          ]}
        />
      </CardBody>
      <CardFooter>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          colSpan={3}
          count={count}
          onChangePage={(event, newPage) => onChangePageHandler(newPage)}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={event =>
            onChangeRowPerPagaHandler(parseInt(event.target.value))
          }
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false
          }}
          ActionsComponent={TablePaginationActions}
        />
      </CardFooter>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    topGrowth: state.variance.topGrowth,
    category: state.variance.category
  };
};

const mapDispatchToProps = dispatch => {
  return {
    set: value => dispatch(actionCreator.save(value)),
    toggleLoadding: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value)),
    changeSearchParam: (property, value) =>
      dispatch(actionCreator.changeSearchParam(property, value)),
    setSingle: (property, value) => dispatch(actionCreator.set(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GrowthRate));
