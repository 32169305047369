import React, { useState, useEffect } from "react";
import { FormControlLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import FeatureSearchService from "../../services/FeatureSearchService";
import styles from "./styles/features";

const useStyles = makeStyles(styles);

const CheckBoxesList = props => {
  const { checkBoxes, handleAllChecked, handleCheckboxes } = props;
  const [allChecked, checkAll] = useState(true);
  const classes = useStyles();

  let checker = arr => arr.every(Boolean);

  // eslint-disable-next-line
  useEffect(() => {
    let chbs = [];
    checkBoxes.forEach((chb, i) => {
      chbs.push(chb.isChecked);
    });
    if (checker(chbs)) {
      checkAll(true);
    } else {
      checkAll(false);
    }
  });

  return (
    <Card>
      <CardBody>
        <div className={classes.checkboxContainer}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allChecked}
                onChange={handleAllChecked}
                name="allChecked"
                color="primary"
              />
            }
            label="Alle"
          />
          {checkBoxes.map((chb, i) => {
            return (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={chb.isChecked}
                    onChange={handleCheckboxes}
                    name={chb.name}
                    color="primary"
                  />
                }
                label={FeatureSearchService.formatFeatureName(chb.name)}
              />
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

export default CheckBoxesList;
