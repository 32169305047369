import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Table from "../Table/Table";
import CardFooter from "../Card/CardFooter";
import TablePaginationActions from "../PaginatorActions/PaginatorActions";
import GridItem from "../Grid/GridItem";
import Loader from "../../ui/Loader/Loader";
import FIlterFieldsComponent from "./FIlterFields.component";
import ProductTableService from "../../services/ProductTableService";

import styles from "./styles/productTableStyles";
import * as actionCreator from "../../store/actions";

const useStyles = makeStyles(styles);

const ProductTableComponent = props => {
  const classes = useStyles();

  const {
    tableHead,
    tableBodyData,
    checkForEmptyValues,
    initialState,
    setUrlParams,
    removeUrlParams
  } = ProductTableService;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formValues, changeFormValues] = useState(initialState);
  const [sortDir, toggleSortDir] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const { products, loading, categories } = props;
  const { data, total } = products;
  const tableData = tableBodyData(data, page, rowsPerPage);
  const isButtonActive = checkForEmptyValues(Object.values(formValues));

  useEffect(() => {
    props.getProductTable(formValues, rowsPerPage, page + 1, "", "");
    // eslint-disable-next-line
  }, []);

  const updateField = (e, name) => {
    changeFormValues({
      ...formValues,
      [name]: e.target.value
    });
    if (name === "limited") {
      changeFormValues({
        ...formValues,
        [name]: e.target.checked
      });
    }
  };

  const onFilterClick = () => {
    setUrlParams(formValues, Object.keys(formValues));
    props.getProductTable(formValues, rowsPerPage, page + 1, "", "");
    setPage(0);
  };
  const onResetFilterClick = () => {
    removeUrlParams();
    changeFormValues({ ...initialState });
    props.getProductTable(initialState, rowsPerPage, page + 1, "", "");
    setPage(0);
  };

  const onChangePageHandler = page => {
    const _orderBy = orderBy || "product_zip";
    setPage(page);
    props.getProductTable(formValues, rowsPerPage, page + 1, _orderBy, sortDir);
  };

  const onChangeRowPerPagaHandler = rPerPage => {
    const _orderBy = orderBy || "product_zip";
    setPage(0);
    setRowsPerPage(rPerPage);
    props.getProductTable(formValues, rPerPage, page + 1, _orderBy, sortDir);
  };

  const onNameClick = productId => {
    props.history.push(`/admin/single-product/${productId}`);
  };

  const onChangeOrder = key => {
    if (key !== undefined) {
      const oppositeDir = sortDir === "asc" ? "desc" : "asc";
      toggleSortDir(oppositeDir);
      setOrderBy(key);
      props.getProductTable(
        formValues,
        rowsPerPage,
        page + 1,
        key,
        oppositeDir
      );
    }
  };

  return (
    <Card>
      <CardHeader color={"info"} className={classes.productHeader}>
        Product Table
      </CardHeader>
      <FIlterFieldsComponent
        onFilterClick={onFilterClick}
        onResetFilterClick={onResetFilterClick}
        formValues={formValues}
        updateField={updateField}
        isButtonDisabled={isButtonActive}
        categories={categories}
      />
      {!loading ? (
        <GridItem xs={12} sm={12} md={12}>
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableData={tableData}
              isClickable={true}
              onCellClick={onNameClick}
              clickableKey={1}
              tableHead={tableHead}
              onChangeOrder={onChangeOrder}
              order={sortDir}
              orderBy={orderBy}
            />
          </CardBody>
        </GridItem>
      ) : (
        <Loader />
      )}
      <CardFooter>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          colSpan={3}
          count={total || 0}
          onChangePage={(event, newPage) => onChangePageHandler(newPage)}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={event =>
            onChangeRowPerPagaHandler(parseInt(event.target.value))
          }
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: false
          }}
          ActionsComponent={TablePaginationActions}
        />
      </CardFooter>
    </Card>
  );
};

const mapStateToProps = state => {
  const { productTable, search } = state;
  return {
    products: productTable.products,
    loading: productTable.productsLoading,
    categories: search.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProductTable: (params, rowsPerPage, page, key, sortDir) =>
      dispatch(
        actionCreator.getProductTable(params, rowsPerPage, page, key, sortDir)
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProductTableComponent));
