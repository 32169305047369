import React from "react";
import { PieChart, Pie, Legend, Cell, Label } from "recharts";
import { makeStyles } from "@material-ui/core/styles";

// CORE COMPONENTS
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";

import {
  renderActiveShape,
  capitalizeName,
  checkEmptyCharts
} from "./PieChartDiagram.utilities";

import styles from "./pieChart.style";
const useStyles = makeStyles(styles);

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#58508d",
  "#bc5090",
  "#ff6361",
  "#ffa600",
  "#FF8042",
  "#003f5c",
  "#665191"
];

const PieChartDiagram = props => {
  const classes = useStyles();

  const { graphicsData } = props;
  // Diagrams params
  const cx = 300;
  const cy = 200;
  const smallDiagramInnerRadius = 70;
  const smallDiagramOuterRadius = 100;
  const bigDiagramInnerRadius = 100;
  const bigDiagramOuterRadius = 115;

  const generateVermitenDataMultiArr = (key1, key2) => {
    return graphicsData?.map(item => {
      return [{ value: item[key1] }, { value: item[key2] - item[key1] }];
    });
  };

  const NachFlacheSecondDataArr = generateVermitenDataMultiArr(
    "_sum_product_area",
    "sum_product_area"
  )?.flat(1000);
  const NachMietenSecondDataArr = generateVermitenDataMultiArr(
    "_sum_product_price",
    "sum_product_price"
  )?.flat(1000);

  const legendArr = (function() {
    return graphicsData?.map((item, idx) => {
      return { value: capitalizeName(item.name), color: COLORS[idx] };
    });
  })();

  const isNachFlachChart = checkEmptyCharts(NachFlacheSecondDataArr);
  const isNachMietenChart = checkEmptyCharts(NachMietenSecondDataArr);

  return (
    <GridContainer className={classes.pieChartsContainer}>
      {!isNachFlachChart && (
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <PieChart width={900} height={420}>
            <Pie
              data={NachFlacheSecondDataArr}
              dataKey="value"
              cx={cx}
              cy={cy}
              innerRadius={bigDiagramInnerRadius}
              outerRadius={bigDiagramOuterRadius}
            >
              {NachFlacheSecondDataArr.map((entry, index) => {
                const withColor = (index + 1) % 2 !== 0;
                return (
                  <Cell
                    key={index}
                    fill={withColor ? "#00ccff9b" : "#003f5c00"}
                  />
                );
              })}
            </Pie>
            <Pie
              data={graphicsData}
              cx={cx}
              cy={cy}
              innerRadius={smallDiagramInnerRadius}
              outerRadius={smallDiagramOuterRadius}
              fill="#8884d8"
              dataKey="sum_product_area"
              label={props => renderActiveShape(props, true)}
            >
              <Label value="Nach Fläche" offset={0} position="center" />
              {graphicsData.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>

            <Legend payload={legendArr} />
          </PieChart>
        </GridItem>
      )}
      {!isNachMietenChart && (
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <PieChart width={900} height={420}>
            <Pie
              data={NachMietenSecondDataArr}
              dataKey="value"
              cx={cx}
              cy={cy}
              innerRadius={bigDiagramInnerRadius}
              outerRadius={bigDiagramOuterRadius}
            >
              {NachMietenSecondDataArr.map((entry, index) => {
                const withColor = (index + 1) % 2 !== 0;
                return (
                  <Cell
                    key={index}
                    fill={withColor ? "#00ccff9b" : "#003f5c00"}
                  />
                );
              })}
            </Pie>
            <Pie
              data={graphicsData}
              cx={cx}
              cy={cy}
              innerRadius={smallDiagramInnerRadius}
              outerRadius={smallDiagramOuterRadius}
              fill="#8884d8"
              dataKey="sum_product_price"
              label={renderActiveShape}
            >
              <Label value="Nach Mieten" offset={0} position="center" />
              {graphicsData.map((entry, index) => (
                <Cell key={index} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default PieChartDiagram;
