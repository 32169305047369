import React from "react";
import Loader from "../../ui/Loader/Loader";

const WrapperWithLoader = ({ children, isLoading, isFetched }) =>
  isLoading ? (
    <Loader />
  ) : isFetched || isFetched === undefined ? (
    children
  ) : null;

export default WrapperWithLoader;
