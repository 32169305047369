import React from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

// core components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";

import styles from "./styles/dashboardStyle.js";
import CardHeader from "../Card/CardHeader";

const useStyles = makeStyles(styles);

const Progress = props => {
  const classes = useStyles();
  const {
    color,
    title,
    saleValue,
    rentValue,
    rentPercentage,
    salePercentage
  } = props;

  return (
    <Card>
      <CardHeader color={color} stats>
        <h5>{title}</h5>
      </CardHeader>
      <CardBody>
        <h3 className={classes.cardTitle}>{saleValue}</h3>
        <div className={classes.progressHeader}>
          <p>Verkauf</p>
          <p>{salePercentage}%</p>
        </div>
        <LinearProgress variant="determinate" value={salePercentage} />
      </CardBody>
      <CardBody>
        <h3 className={classes.cardTitle}>{rentValue}</h3>
        <div className={classes.progressHeader}>
          <p>Miete</p>
          <p>{rentPercentage}%</p>
        </div>
        <LinearProgress
          color={"secondary"}
          variant="determinate"
          value={rentPercentage}
        />
      </CardBody>
    </Card>
  );
};

export default Progress;
