import React, { useState, useEffect } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Table from "components/Table/Table";
import Loader from "../../ui/Loader/Loader";
import CardHeader from "../../components/Card/CardHeader";
import GridItem from "../../components/Grid/GridItem";
import ProductTableService from "../../services/ProductTableService";
import ProductTableApi from "../../api/ProductTableApi";

const SingleProductTable = props => {
  const { getSingleProductBody } = ProductTableService;

  const [responseData, setResponseData] = useState([]);
  const [loading, toggleLoading] = useState(false);
  const { productId } = props.match.params;
  const tableBody = getSingleProductBody(responseData);

  useEffect(() => {
    toggleLoading(true);
    ProductTableApi.getProduct(productId)
      .then(resp => {
        setResponseData(resp.data.data);
        toggleLoading(false);
      })
      .catch(err => console.log(err));
    // eslint-disable-next-line
  }, []);

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color={"info"}>Product Details</CardHeader>
        {!loading ? (
          <CardBody>
            <Table
              tableHeaderColor="info"
              tableData={tableBody}
              tableHead={[]}
            />
          </CardBody>
        ) : (
          <Loader />
        )}
      </Card>
    </GridItem>
  );
};

export default SingleProductTable;
