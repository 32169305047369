import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";

// CORE COMPONENTS
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import CardBody from "../../Card/CardBody";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CustomInput from "../../CustomInput/CustomInput";
import Button from "../../CustomButtons/Button";
import FieldGroup from "../FieldGroup/FieldGroup.component";

import styles from "./form.style";
// ACTIONS
import {
  closeSuggestionModal,
  getFieldTypingSuggestions,
  getMarketAnalyseDataAsync,
  resetData
} from "store/reducers/market-analyse/market-analyse.actions";
import { generateRandomText } from "config/random-text";

const useStyles = makeStyles(styles);

const Form = props => {
  const {
    getMarketAnalyseDataAsync,
    getFieldTypingSuggestions,
    closeSuggestionModal,
    suggestions,
    isSuggestionsModal,
    suggestionsObject,
    categories,
    resetData
  } = props;
  const blankForm = {
    name: "",
    list: []
  };
  const classes = useStyles();
  const [inputState, setInputState] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [groups, setGroups] = useState([]);
  const [notification, setNotification] = useState("");

  const inputsParams = [
    {
      label: "ZIP",
      name: "zip",
      type: "number"
    },
    {
      label: "City",
      name: "city",
      type: "text"
    }
  ];

  const canSubmit = groups.map(group => {
    return !(group.name === "" || group.list.length <= 0);
  });

  useEffect(() => {
    if (canSubmit[0] === true) {
      setNotification("");
    }
  }, [notification, canSubmit]);

  const handleChange = event => {
    if (isSubmitted) {
      setIsSubmitted(false);
    }
    const { value, name } = event.target;

    if (!value) {
      setIsSubmitted(true);
      resetData();
    }
    if (value.length > 1 && name !== "name") {
      getFieldTypingSuggestions({ name, value, zip: inputState.zip, city: inputState.city });
    }

    setInputState({
      ...inputState,
      [name]: value
    });
  };

  const onSubmit = () => {
    setIsSubmitted(true);
    const categories = inputState?.categories?.map(category => category.value);

    //remove not valid groups
    const validGroups = groups.filter(
      group => group.name !== "" && group.list.length > 0
    );
    if (canSubmit[0] === true) {
      setNotification("");
      setGroups(validGroups);
      getMarketAnalyseDataAsync({ ...inputState, categories, groups });
    } else {
      setNotification("Please fill in the field");
    }
  };

  const handleSuggestionsClick = (name, suggestion) => {
    setIsSubmitted(true);
    if (name === "zip") {
      suggestionsObject.forEach(sug => {
        if (sug.zip_city === suggestion) {
          setInputState({
            ...inputState,
            zip: sug.zip,
            city: sug.city
          });
        }
      });
    }
    if (name === "city") {
      suggestionsObject.forEach(sug => {
        if (sug.city === suggestion) {
          setInputState({
            ...inputState,
            city: sug.city
          });
        }
      });
    }

    closeSuggestionModal();
  };

  const options = categories.map(category => {
    return {
      value: category.type_name,
      label: category.type_name
    };
  });

  const NoOptionsMessage = props => {
    return (
      <div className={classes.message}>
        You are able to choose maximum 3 categories.
      </div>
    );
  };

  const addFormGroup = () => {
    setNotification("");
    const id = generateRandomText();
    setInputState({
      ...inputState,
      categories: []
    });
    setGroups([...groups, { ...blankForm, id }]);
  };

  const handleGroupField = (e, type, index) => {
    const updatedGroups = [...groups];

    if (type === "select") {
      updatedGroups[index].list = e ? e.map(l => l.value) : [];

      //automatically set the first selected category as the group name
      setAutoGroupName(e, updatedGroups, index);

      setGroups(updatedGroups);
    }
    if (type === "input") {
      updatedGroups[index].name = e.target.value;
      updatedGroups[index].isNameTyped = true;
      setGroups(updatedGroups);
    }
  };

  const setAutoGroupName = (e, updatedGroups, index) => {
    if (updatedGroups[index].isNameTyped) {
      return updatedGroups[index].name;
    } else {
      if (updatedGroups[index].list.length === 1) {
        updatedGroups[index].name = e[0].value;
      } else if (updatedGroups[index].list.length === 0) {
        updatedGroups[index].name = "";
      }
    }
  };

  const removeGroup = id => {
    const updatedGroups = groups.filter(group => group.id !== id);
    setGroups(updatedGroups);
  };

  let disabled = false;
  if (inputState.zip && inputState.zip !== "") {
    disabled = true;
  } else if (!inputState.zip || inputState.zip === "") {
    disabled = !!(inputState.city && inputState.city !== "");
  } else {
    disabled = false;
  }

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Market Analyse</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          {inputsParams.map(({ name, type, label }, idx) => (
            <GridItem xs={12} sm={12} md={6} key={idx}>
              <CustomInput
                labelText={label}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: type,
                  onChange: handleChange,
                  value: inputState?.[name] || "",
                  name: name,
                  required: true
                }}
                suggestions={
                  isSubmitted ? null : suggestions && suggestions[name]
                }
                onSuggestionClick={value => handleSuggestionsClick(name, value)}
                isSuggestionsModal={isSuggestionsModal}
              />
            </GridItem>
          ))}
          {groups.length > 0 &&
            groups.map((group, index) => {
              return (
                <FieldGroup
                  key={group.id}
                  handleChange={handleGroupField}
                  groups={groups}
                  options={options}
                  NoOptionsMessage={NoOptionsMessage}
                  removeGroup={removeGroup}
                  setGroups={setGroups}
                  index={index}
                />
              );
            })}
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={classes.buttonsContainer}
          >
            <div>
              <Tooltip
                title="Create new groups"
                placement="top"
                aria-label="add"
              >
                <Fab
                  disabled={groups.length > 2}
                  onClick={addFormGroup}
                  color="primary"
                  className={classes.fab}
                >
                  <AddIcon />
                </Fab>
              </Tooltip>
              {notification !== "" && (
                <span className={classes.notification}>{notification}</span>
              )}
            </div>
            <Button
              onClick={onSubmit}
              color="primary"
              round
              disabled={!disabled}
            >
              Suchen
            </Button>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = state => {
  const { marketAnalyse, search } = state;

  return {
    suggestions: marketAnalyse.typingSuggestions,
    isSuggestionsModal: marketAnalyse.isSuggestionsModal,
    suggestionsObject: marketAnalyse.suggestionsObject,
    categories: search.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getMarketAnalyseDataAsync: params =>
      dispatch(getMarketAnalyseDataAsync(params)),
    getFieldTypingSuggestions: params =>
      dispatch(getFieldTypingSuggestions(params)),
    closeSuggestionModal: () => dispatch(closeSuggestionModal()),
    resetData: () => dispatch(resetData())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
