import React, { Component } from "react";
import { connect } from "react-redux";
import SearchApi from "../../api/Search";

import PaginatedTable from "../Table/PaginatedTable";
import * as actionCreator from "../../store/actions";

class Table extends Component {
  state = {
    order: "ASC",
    orderBy: ""
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      nextProps.results.count === 0 &&
      nextProps.results.count === this.props.results.count &&
      nextProps.results.rows.length === this.props.results.rows.length &&
      nextState.order === this.state.order
    );
  }

  onSaveRows = params => {
    this.props.setSingle("markers", []);

    SearchApi.getPaginatinTotalCount(params)
      .then(resp => {
        this.props.setSingle("propertyTotalCount", resp.data.data.total);
      })
      .catch(err => console.log(err));
    SearchApi.propertyList(params).then(resp => {
      const rows = resp.data.data.result_total;
      const count = resp.data.data.total;

      this.props.setMultiple({
        rows,
        count
      });
      this.props.setSingle("markers", rows);

      this.props.toggleLoading("list", false);
    });
  };

  componentDidMount() {
    const { searchParams } = this.props;
    this.props.toggleLoading("list", true);
    //on first call set separatevalule to all
    searchParams.separateValue = "";
    this.onSaveRows(searchParams);
  }

  onRowsPerPageChanged = event => {
    this.props.toggleLoading("list", true);
    const rows = parseInt(event.target.value, 10);
    const params = { ...this.props.searchParams };
    //on first call set separatevalule to all
    params.separateValue = "";
    this.props.changeSeachParam("rowsPerPage", rows);
    this.props.changeSeachParam("page", 0);

    params.rowsPerPage = rows;
    params.page = 0;

    this.onSaveRows(params);
  };

  onPageChanged = (event, newPage) => {
    this.props.toggleLoading("list", true);
    const params = { ...this.props.searchParams };
    params.page = newPage;
    //on first call set separatevalule to all
    params.separateValue = "";
    this.props.changeSeachParam("page", newPage);

    this.onSaveRows(params);
  };

  onChangeOrder = key => {
    const direction = this.state.order;
    const oppositeDir = direction === "ASC" ? "DESC" : "ASC";
    const params = { ...this.props.searchParams };
    params.page = 0;
    params.sortBy = key;
    params.direction = direction;
    //on first call set separatevalule to all
    params.separateValue = "";
    this.props.changeSeachParam("page", 0);

    if (key !== undefined) {
      this.props.toggleLoading("list", true);

      this.onSaveRows(params);
      this.setState({
        order: oppositeDir,
        orderBy: key
      });
    }
  };

  onFilterParamsChanged = (value, property) => {
    this.props.toggleLoading("list", true);
    let separateValue = "";
    const params = { ...this.props.searchParams };
    if (property === "categoryValue") {
      separateValue = params.separateValue;
    } else {
      separateValue = "";
    }
    params.separateValue = separateValue;

    this.props.changeSeachParam(property, value);

    params[property] = value;

    this.onSaveRows(params);
  };

  onCloseTitleFilter = () => {
    this.props.toggleLoading("list", true);
    const params = { ...this.props.searchParams };
    //on first call set separatevalule to all
    params.separateValue = "";

    this.onSaveRows(params);
  };

  render() {
    return (
      <PaginatedTable
        onFilterChanged={this.onFilterParamsChanged}
        onCloseTitleFilter={this.onCloseTitleFilter}
        onChangeOrder={this.onChangeOrder}
        onPageChanged={this.onPageChanged}
        onRowsPerPageChanged={this.onRowsPerPageChanged}
        page={this.props.searchParams.page}
        rowsPerPage={this.props.searchParams.rowsPerPage}
        rows={this.props.results.rows}
        count={Number(this.props.propertyTotalCount)}
        separateValue={this.props.searchParams.separateValue}
        filterParams={{
          month: this.props.searchParams.month,
          site: this.props.searchParams.site,
          type: this.props.searchParams.type,
          categoryValue: this.props.searchParams.categoryValue,
          titleValue: this.props.searchParams.titleValue,
          status: this.props.searchParams.status
        }}
        changeSerchParam={this.props.changeSeachParam}
        filterYear={this.props.filterYear}
        filterMonth={this.props.filterMonth}
        order={this.state.order}
        orderBy={this.state.orderBy}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    searchParams: {
      lat: state.search.lat,
      lng: state.search.lng,
      zipcode: state.search.zipcode,
      address: state.search.address,
      radius: state.search.radius,
      areaType: state.search.areaType,
      category: state.search.category,
      rentalPercent: state.search.rentalPercent,
      sizeFrom: state.search.sizeFrom,
      sizeTo: state.search.sizeTo,
      priceFrom: state.search.priceFrom,
      priceTo: state.search.priceTo,
      yearFrom: state.search.yearFrom,
      yearTo: state.search.yearTo,
      rowsPerPage: state.search.rowsPerPage,
      page: state.search.page,
      month: state.search.month,
      site: state.search.site,
      type: state.search.type,
      categoryValue: state.search.categoryValue,
      separateValue: state.search.separateValue,
      titleValue: state.search.titleValue,
      status: state.search.status,
      rent_general: state.search.rent_general
    },
    results: {
      rows: state.results.rows,
      total: state.results.totalResults
    },
    loaders: {
      list: state.ui.list
    },
    filterYear: state.search.filterYear,
    filterMonth: state.search.filterMonth,
    propertyTotalCount: state.results.propertyTotalCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeSeachParam: (property, value) =>
      dispatch(actionCreator.changeSearchParam(property, value)),
    setMultiple: value => dispatch(actionCreator.setMultiple(value)),
    toggleLoading: (property, value) =>
      dispatch(actionCreator.toggleLoadding(property, value)),
    setSingle: (property, value) => dispatch(actionCreator.set(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
