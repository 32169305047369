const avatarStyle = theme => ({
  root: {
    display: "flex",
    marginTop: "22px",
    "& > *": {
      margin: theme.spacing(1),
      borderRadius: "4px"
    }
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20)
  },
  fullWidth: {
    width: "100%",
    height: "auto"
  }
});

export default avatarStyle;
