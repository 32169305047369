import React from "react";
import { connect } from "react-redux";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Aux from "../../hoc/AuxReact";
import Form from "../../components/HighPerformer/Form.component";
import PerformerTable from "../../components/HighPerformer/PerformerTable";

import * as actionCreator from "../../store/actions/index";

const HighPerformer = props => {
  const {
    changeSearchParam,
    getShortTermPerformer,
    getLongTermPerformer,
    getShortTermRentPerformer,
    getShortTermBuyPerformer,
    getObjectTableData,
    searchParams,
    categories,
    shortTermDataLoading,
    longTermDataLoading,
    shortTermRentDataLoading,
    shortTermBuyDataLoading,
    shortTermData,
    longTermData
  } = props;

  const onSubmitHandler = () => {
    const { year, month } = searchParams;
    searchParams.actual_time = `${year}-${month}`;

    getShortTermPerformer(searchParams, 10, 1);
    getLongTermPerformer(searchParams, 10, 1);
    getShortTermRentPerformer(searchParams, 10, 1);
    getShortTermBuyPerformer(searchParams, 10, 1);
    getObjectTableData(searchParams, 10, 1)
  };

  return (
    <Aux>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Form
            changeSearchParam={changeSearchParam}
            searchParams={searchParams}
            categories={categories}
            onSubmit={onSubmitHandler}
            shortTermDataLoading={shortTermDataLoading}
            longTermDataLoading={longTermDataLoading}
            shortTermRentDataLoading={shortTermRentDataLoading}
            shortTermBuyDataLoading={shortTermBuyDataLoading}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <PerformerTable
            shortTermData={shortTermData}
            longTermData={longTermData}
            shortTermDataLoading={shortTermDataLoading}
            longTermDataLoading={longTermDataLoading}
          />
        </GridItem>
      </GridContainer>
    </Aux>
  );
};

const mapStateToProps = state => {
  const { highPerformer, search } = state;
  return {
    shortTermData: highPerformer.shortTermData,
    longTermData: highPerformer.longTermData,
    searchParams: highPerformer.searchParams,
    longTermDataLoading: highPerformer.longTermDataLoading,
    shortTermDataLoading: highPerformer.shortTermDataLoading,
    shortTermRentDataLoading: highPerformer.shortTermRentDataLoading,
    shortTermBuyDataLoading: highPerformer.shortTermBuyDataLoading,
    categories: search.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeSearchParam: (name, value) =>
      dispatch(actionCreator.changePerformerSearchParam(name, value)),
    getShortTermPerformer: (params, rowsPerPage, page) =>
      dispatch(actionCreator.getShortTermPerformer(params, rowsPerPage, page)),
    getLongTermPerformer: (params, rowsPerPage, page) =>
      dispatch(actionCreator.getLongtTermPerformer(params, rowsPerPage, page)),
    getShortTermRentPerformer: (params, rowsPerPage, page, days) =>
      dispatch(
        actionCreator.getShortTermRentPerformer(params, rowsPerPage, page, days)
      ),
    getShortTermBuyPerformer: (params, rowsPerPage, page, days) =>
      dispatch(
        actionCreator.getShortTermBuyPerformer(params, rowsPerPage, page, days)
      ),
    getObjectTableData: (params, rowsPerPage, page) =>
      dispatch(actionCreator.getObjectTableDataAsync(params, rowsPerPage, page))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HighPerformer);
