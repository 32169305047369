import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import create from "react-form-validation-hoc";

import styles from "./styles/formStyle";

// core components
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Card from "../Card/Card";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import { required } from "../../config/validator-rules";
import CustomInput from "../CustomInput/CustomInput";

const useStyles = makeStyles(styles);

const Form = props => {
  const classes = useStyles();

  const { FieldDecorator, errors } = props.validator;

  const {
    onChangeSearchParam,
    onSubmit,
    isLoadding,
    suggestions,
    onSuggestionClick,
    isSuggestionsModal,
    objectName
  } = props;

  const { featuresSearch, index } = props.params;

  let isFormValid = false;
  if (
    featuresSearch[index].zip &&
    featuresSearch[index].zip !== "" &&
    featuresSearch[index].city &&
    featuresSearch[index].city !== ""
  ) {
    isFormValid = true;
  } else if (!featuresSearch[index].zip || featuresSearch[index].zip === "") {
    isFormValid = !!(
      featuresSearch[index].streetname &&
      featuresSearch[index].streetname !== "" &&
      featuresSearch[index].city &&
      featuresSearch[index].city !== ""
    );
  } else {
    isFormValid = false;
  }

  return (
    <GridItem xs={12} sm={12} md={12} className={classes.splitFormsContainer}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>{objectName}</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="ZIP"
                id={`zip_${index}`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: event =>
                    onChangeSearchParam("zip", event.target.value, index),
                  value: featuresSearch[index].zip
                }}
                suggestions={suggestions.zip}
                onSuggestionClick={value =>
                  onSuggestionClick("zip", value, index)
                }
                isSuggestionsModal={isSuggestionsModal}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <FieldDecorator
                name={"city"}
                value={featuresSearch[index].city}
                rules={[required("city")]}
              />
              <CustomInput
                labelText="City"
                id={`city_${index}`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  onChange: event =>
                    onChangeSearchParam("city", event.target.value, index),
                  value: featuresSearch[index].city
                }}
                message={{ error: errors.city }}
                suggestions={suggestions.city}
                onSuggestionClick={value =>
                  onSuggestionClick("city", value, index)
                }
                isSuggestionsModal={isSuggestionsModal}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Street Name"
                id={`streetname_${index}`}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "text",
                  onChange: event => {
                    onChangeSearchParam(
                      "streetname",
                      event.target.value,
                      index
                    );
                  },
                  value: featuresSearch[index].streetname
                }}
                suggestions={suggestions.streetname}
                onSuggestionClick={value =>
                  onSuggestionClick("streetname", value, index)
                }
                isSuggestionsModal={isSuggestionsModal}
              />
            </GridItem>
          </GridContainer>
          {featuresSearch.length <= 1 && (
            <GridItem xs={12} sm={12} md={6}>
              <Button
                onClick={() => onSubmit(index)}
                color={"primary"}
                round
                disabled={isLoadding || !isFormValid}
              >
                Suchen
              </Button>
            </GridItem>
          )}
          {featuresSearch.length >= 1 && index === 1 && (
            <GridItem xs={12} sm={12} md={6}>
              <Button
                onClick={() => onSubmit(index)}
                color={"primary"}
                round
                disabled={isLoadding || !isFormValid}
              >
                Suchen
              </Button>
            </GridItem>
          )}
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default create()(Form);
