import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Loader from "../../ui/Loader/Loader";
import FeaturesMap from "./Map";
import CheckBoxesList from "./CheckBoxesList";
import FeaturesCards from "./FeaturesCards";
import styles from "./styles/features";
const useStyles = makeStyles(styles);

const ResultsContainer = props => {
  const {
    loaders,
    results,
    responseError,
    featuresSearch,
    checkBoxes,
    handleAllChecked,
    handleCheckboxes,
    isFetureSearchSplitted
  } = props;
  const classes = useStyles();
  const mapContainer = classNames({
    [classes.mapContainer]: !isFetureSearchSplitted,
    [classes.splittedMapContainer]: isFetureSearchSplitted,
  });

  return (
    <React.Fragment>
      <div className={classes.splitResultsContainer}>
        {featuresSearch.map((p, i) => {
          return (
            <GridItem xs={12} sm={12} md={12} lg={12} key={i}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {loaders[i].featuresLoading === true && (
                    <div className={classes.searchResult}>
                      <Loader />
                    </div>
                  )}
                  {loaders[i].featuresLoading === false &&
                  responseError[i] === "" ? (
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <GridContainer>
                        <div className={mapContainer}>
                          <GridItem xs={12} sm={12} md={12} lg={12}>
                            <FeaturesMap
                              index={i}
                              center={{
                                lat: featuresSearch[i].lat,
                                lng: featuresSearch[i].lng
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <CheckBoxesList
                              checkBoxes={checkBoxes}
                              handleAllChecked={handleAllChecked}
                              handleCheckboxes={handleCheckboxes}
                            />
                          </GridItem>
                        </div>
                      </GridContainer>
                    </GridItem>
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>
            </GridItem>
          );
        })}
      </div>
      {results.length > 0 &&
        ((loaders[0] && loaders[0].featuresLoading === false) ||
          (loaders[1] && loaders[1].featuresLoading === false)) && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <FeaturesCards
              color={"info"}
              checkedItems={checkBoxes}
              results={results}
              featuresSearchParams={featuresSearch}
              isFetureSearchSplitted={isFetureSearchSplitted}
              responseError={responseError}
            />
          </GridItem>
        )}
    </React.Fragment>
  );
};

export default ResultsContainer;
