const SociographicService = {
  checkForNulls(array) {
    let values = [];
    array &&
      array.forEach(item => {
        values.push(Object.values(item)[0]);
      });
    return values.every(i => i === null);
  },
  generateChartData(array) {
    let label = [];
    let series = [];
    let chartData = {};
    array &&
      array.forEach(item => {
        label.push(Object.keys(item)[0]);
        series.push(Object.values(item)[0]);
      });
    chartData = { labels: label, series: [series] };
    return chartData;
  }
};

export default SociographicService;
