import React, { Component } from "react";

import Aux from "../../hoc/AuxReact";
import { Collapse } from "@material-ui/core";

import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import Table from "../Table/Table";
import Card from "../Card/Card";

import NewsAndStatistaApi from "../../api/NewsAndStatistaApi";
import Switcher from "../Collapse/Switcher";

class NewsAndStatista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsCount: 0,
      statisticsCount: 0,
      news: [],
      statistics: [],
      googleIsOpen: false,
      statisticsIsOpen: false
    };

    this.city = props.city;
  }

  onToggleCollapse = property => {
    const oldState = { ...this.state };
    oldState[property] = !oldState[property];
    this.setState({
      ...oldState
    });
  };

  componentDidMount() {
    NewsAndStatistaApi.get(this.city)
      .then(resp => resp.data.data)
      .then(data =>
        this.setState({
          ...this.state,
          news: data.news.articles,
          newsCount: data.news.articleCount,
          statistics: data.statistics,
          statisticsCount: data.statistics.length
        })
      )
      .catch(err => console.error(err));
  }

  render() {
    return (
      <Aux>
        <Card>
          <CardHeader color="warning">
            <h3>News</h3>
            <p>Gesamt: {this.state.newsCount}</p>
          </CardHeader>
          {this.state.newsCount > 0 && (
            <CardBody>
              <Switcher
                title={"Show/Hide table"}
                isOpen={this.state.googleIsOpen}
                onClick={() => this.onToggleCollapse("googleIsOpen")}
              />
              <Collapse in={this.state.googleIsOpen} timeout="auto" unmountOnExit>
                <Table
                  tableHead={["", "Title", "Description", "Publised At"]}
                  tableDataWithIcon={this.state.news}
                  tableData={[]}
                />
              </Collapse>
            </CardBody>
          )}
        </Card>

        <Card>
          <CardHeader color="danger">
            <h3>Analytics</h3>
            <p>Gesamt: {this.state.statisticsCount}</p>
          </CardHeader>
          {this.state.statisticsCount > 0 && (
            <CardBody>
              <Switcher
                title={"Show/Hide table"}
                isOpen={this.state.statisticsIsOpen}
                onClick={() => this.onToggleCollapse("statisticsIsOpen")}
              />
              <Collapse
                in={this.state.statisticsIsOpen}
                timeout="auto"
                unmountOnExit
              >
                <Table
                  tableHead={["", "Title", "Description", "Publised At"]}
                  tableDataWithIcon={this.state.statistics}
                  tableData={[]}
                />
              </Collapse>
            </CardBody>
          )}
        </Card>
      </Aux>
    );
  }
}

export default NewsAndStatista;
