import React from "react";

const url_string = window.location.href;
const url = new URL(url_string);

const ProductTableService = {
  tableBodyData(arr, page, rowsPerPage) {
    let tableData = [];

    arr &&
      arr.map((item, index) => {
        const number = {
          label: page * rowsPerPage + index + 1
        };
        const productId = {
          label: item.product_id
        };
        const url = {
          label: React.createElement(
            "a",
            { href: item.product_url, target: "_blank" },
            sliceText(item.product_url, 25)
          )
        };
        const name = {
          label: sliceText(checkForEmptyValues(item.product_name), 25)
        };
        const zip = {
          label: sliceText(checkForEmptyValues(item.product_zip), 35)
        };
        const coveredArea = {
          label: sliceText(checkForEmptyValues(item.product_covered_area), 35)
        };
        const price = {
          label: `${
            item.product_price !== ""
              ? `${parseInt(item.product_price).toLocaleString()}`
              : "―\t"
          }`
        };
        const type = {
          label: sliceText(checkForEmptyValues(item.type_property), 35)
        };
        const location = {
          label: sliceText(checkForEmptyValues(item.product_location), 15)
        };
        const createdAt = {
          label: sliceText(checkForEmptyValues(item.statement_time), 35)
        };
        const changedAt = {
          label: sliceText(checkForEmptyValues(item.statement_changed_at), 35)
        };
        const status = {
          label: sliceText(checkForEmptyValues(item.status), 35)
        };
        const category = {
          label: sliceText(checkForEmptyValues(item.category), 35)
        };

        if (tableData[index] === undefined) {
          tableData[index] = [];
        }

        return tableData[index].push(
          number,
          productId,
          url,
          name,
          zip,
          coveredArea,
          price,
          location,
          type,
          category,
          status,
          createdAt,
          changedAt
        );
      });
    return tableData;
  },
  checkForEmptyValues(arr) {
    return arr.some(function(i) {
      return i !== "" && i !== null && i !== false;
    });
  },
  formatString(str) {
    const string = str.charAt(0) + str.slice(1);
    let filteredLabel = string.split("_").join(" ");

    //removes "product" from start of string
    if (filteredLabel.match(/product/)) {
      filteredLabel = filteredLabel.replace("product", "");
    }
    //to uppercase url and zip labels
    if (filteredLabel.trim() === "url" || filteredLabel.trim() === "zip") {
      filteredLabel = filteredLabel.toUpperCase();
    }
    //make shortcut for covered area
    if (filteredLabel.trim() === "covered area") {
      filteredLabel = "cov. area";
    }

    return (
      filteredLabel
        .trim()
        .charAt(0)
        .toUpperCase() + filteredLabel.trim().slice(1)
    );
  },
  setUrlParams(formValues) {
    url.searchParams.set("product_url", formValues.product_url);
    url.searchParams.set("product_name", formValues.product_name);
    url.searchParams.set("product_zip", formValues.product_zip);
    url.searchParams.set(
      "product_covered_area",
      formValues.product_covered_area
    );
    url.searchParams.set("product_price", formValues.product_price);
    url.searchParams.set("product_location", formValues.product_location);
    url.searchParams.set("type_property", formValues.type_property);
    url.searchParams.set("created_from", formValues.created_from);
    url.searchParams.set("created_to", formValues.created_to);
    url.searchParams.set("changed_from", formValues.changed_from);
    url.searchParams.set("changed_to", formValues.changed_to);
    url.searchParams.set("status", formValues.status);
    url.searchParams.set("category", formValues.category);
    url.searchParams.set("limited", formValues.limited);

    const newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${url.search.slice(1)}`;

    window.history.pushState({ path: newUrl }, "", newUrl);
  },
  removeUrlParams() {
    url.searchParams.delete("product_url");
    url.searchParams.delete("product_name");
    url.searchParams.delete("product_zip");
    url.searchParams.delete("product_covered_area");
    url.searchParams.delete("product_price");
    url.searchParams.delete("product_location");
    url.searchParams.delete("type_property");
    url.searchParams.delete("created_from");
    url.searchParams.delete("created_to");
    url.searchParams.delete("changed_from");
    url.searchParams.delete("changed_to");
    url.searchParams.delete("status");
    url.searchParams.delete("category");
    url.searchParams.delete("limited");

    const newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?${url.search.slice(1)}`;

    window.history.pushState({ path: newUrl }, "", newUrl);
  },
  getSingleProductBody(data) {
    delete data.coords;
    delete data.id;
    delete data.images;
    delete data.mainImage;
    delete data.property_id;

    return (
      data &&
      Object.keys(data).map(item => {
        const string = item.charAt(0).toUpperCase() + item.slice(1);
        return [
          {
            label: string.split("_").join(" ")
          },
          {
            label: data[item]
          }
        ];
      })
    );
  },
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },
  tableHead: [
    {
      label: "#"
    },
    {
      label: "ID"
    },
    {
      label: "URL"
    },
    {
      label: "Name"
    },
    {
      label: "ZIP",
      key: "product_zip"
    },
    {
      label: "Covered area",
      key: "product_covered_area"
    },
    {
      label: "Price",
      key: "product_price"
    },
    {
      label: "Location",
      key: "product_location"
    },
    {
      label: "Type"
    },
    {
      label: "Category"
    },
    {
      label: "Status"
    },
    {
      label: "Created at",
      key: "product_time"
    },
    {
      label: "Changed at",
      key: "status_changed_at"
    }
  ],
  initialState: {
    product_url: url.searchParams.get("product_url") || "",
    product_name: url.searchParams.get("product_name") || "",
    product_zip: url.searchParams.get("product_zip") || "",
    product_covered_area: url.searchParams.get("product_covered_area") || "",
    product_price: url.searchParams.get("product_price") || "",
    product_location: url.searchParams.get("product_location") || "",
    type_property: url.searchParams.get("type_property") || "",
    created_from: JSON.parse(url.searchParams.get("created_from")) || null,
    created_to: JSON.parse(url.searchParams.get("created_to")) || null,
    changed_from: JSON.parse(url.searchParams.get("changed_from")) || null,
    changed_to: JSON.parse(url.searchParams.get("changed_to")) || null,
    status: url.searchParams.get("status") || "",
    category: url.searchParams.get("category") || "",
    limited: JSON.parse(url.searchParams.get("limited")) || false
  }
};

const sliceText = (str, length) => {
  if (str.length > length) {
    return `${str.slice(0, length)}...`;
  }
  return str;
};

const checkForEmptyValues = value => {
  return value === "" || value === null ? "―\t" : value;
};
export default ProductTableService;
