const performerTableStyle = theme => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    "&>div": {
      margin: "0",
      padding: "4px"
    }
  },
  cardHeaderPadding: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  },
  cardHeaderBold: {
    fontWeight: "bold"
  },
  inputLabel: {
    color: "white !important",
    "&.focused": {
      color: "white !important"
    },
    "&.shrink": {
      color: "white"
    }
  },
  filterButton: {
    height: "20px"
  }
});

export default performerTableStyle;
