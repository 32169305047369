import api from "../config/api";

const ProductTableApi = {
  getProductList(
    {
      product_covered_area,
      product_name,
      product_price,
      product_url,
      product_zip,
      product_location,
      type_property,
      created_from,
      created_to,
      changed_from,
      changed_to,
      status,
      category,
      limited
    },
    rowsPerPage,
    page,
    key,
    sortDir
  ) {
    const params = {};
    const sortParams = {};

    if (product_name !== "") params.product_name = product_name;
    if (product_covered_area !== "")
      params.product_covered_area = product_covered_area;
    if (product_price !== "") params.product_price = product_price;
    if (product_url !== "") params.product_url = product_url;
    if (product_zip !== "") params.product_zip = product_zip;
    if (product_location !== "") params.product_location = product_location;
    if (type_property !== "") params.type_property = type_property;
    if (created_from !== null) params.created_from = created_from;
    if (created_to !== null) params.created_to = created_to;
    if (changed_from !== null) params.changed_from = changed_from;
    if (changed_to !== null) params.changed_to = changed_to;
    if (status !== "") params.status = status;
    if (key !== "") sortParams.sortby = key;
    if (sortDir !== "") sortParams.sortdir = sortDir;
    if (category !== "") sortParams.category = category;
    if (limited !== false) sortParams.limited = limited;

    return api.get(`products`, {
      params: {
        ...params,
        rowsPerPage,
        page,
        ...sortParams
      }
    });
  },
  getProduct(productId) {
    return api.get(`product/${productId}`);
  }
};

export default ProductTableApi;
