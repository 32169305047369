export const reduceValues = (tableData, param) => {
  return tableData.reduce((acc, currValue) => acc + currValue[param], 0);
};

export const capitalizeName = str => {
  if (str) {
    if (str === "Wohnen auf Zeit") {
      return str;
    }
    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0]?.toUpperCase() + str[i].substr(1);
    }

    return str.join(" ");
  } else {
    return "";
  }
};

export const myFixed = (x, d) => {
  if (!d) return x.toFixed(d); // don't go wrong if no decimal
  return x?.toFixed(d).replace(/\.?0+$/, "");
};
