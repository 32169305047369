import React from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";
import makeAnimated from "react-select/animated";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";

import GridItem from "../../Grid/GridItem";
import Select from "react-select";
import CustomInput from "../../CustomInput/CustomInput";

import styles from "./fieldGroup.style";
import GridContainer from "../../Grid/GridContainer";

const useStyles = makeStyles(styles);
const animatedComponents = makeAnimated();

const FieldGroup = props => {
  const classes = useStyles();
  const checkIconClasses = classNames({
    [classes.icons]: true,
    [classes.green]: true
  });
  const removeIconClasses = classNames({
    [classes.icons]: true,
    [classes.red]: true
  });

  const {
    handleChange,
    groups,
    options,
    index,
    NoOptionsMessage,
    removeGroup
  } = props;

  return (
    <div className={classes.fieldContainer}>
      <GridItem xs={12} sm={12} md={6}>
        <CustomInput
          labelText={"Enter the name for a new group"}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            type: "text",
            onChange: e => handleChange(e, "input", index),
            value: groups[index]?.name || "",
            name: "name",
            required: true
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={6} className={classes.mgTop35}>
        <GridContainer>
          <GridItem xs={11} sm={11} md={11}>
            <Select
              components={{ NoOptionsMessage, animatedComponents }}
              options={groups[index]?.list?.length === 3 ? [] : options}
              isOptionDisabled={groups[index]?.list?.length === 3}
              isMulti
              onChange={e => handleChange(e, "select", index)}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1} className={classes.iconContainer}>
            {groups[index].name !== "" && groups[index].list.length > 0 && (
              <CheckIcon className={checkIconClasses} />
            )}
            <ClearIcon
              className={removeIconClasses}
              onClick={() => removeGroup(groups[index].id)}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </div>
  );
};

export default FieldGroup;
