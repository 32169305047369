import React from "react";
import { connect } from "react-redux";

import Button from "../CustomButtons/Button";
import CustomItem from "../CustomSelect/CustomItem";
import Aux from "../../hoc/AuxReact";

import * as actionCreator from "../../store/actions";
import { Popover } from "@material-ui/core";

const CustomPopover = ({ filterValues, changeSeachParam, changePerformerSearchParam, onClose, type }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const switchHandler = title => {
    const titles = [...filterValues];

    if (titles.includes(title)) {
      const index = titles.indexOf(title);

      titles.splice(index, 1);
    } else {
      titles.push(title);
    }

    changeSeachParam("titleValue", titles);
    changePerformerSearchParam("titleValue", titles)
  };

  const open = Boolean(anchorEl);

  return (
    <Aux>
      <Button size={"sm"} color="primary" round onClick={handleClick}>
        Filter by name
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <CustomItem
          title={"Erbpacht"}
          isChecked={filterValues.includes("Erbpacht")}
          onSwitch={switchHandler}
        />
        <CustomItem
          title={"Erbbaurecht"}
          isChecked={filterValues.includes("Erbbaurecht")}
          onSwitch={switchHandler}
        />
        <CustomItem
          title={"Zwangsversteigerung"}
          isChecked={filterValues.includes("Zwangsversteigerung")}
          onSwitch={switchHandler}
        />
        <CustomItem
          title={"Rohling"}
          isChecked={filterValues.includes("Rohling")}
          onSwitch={switchHandler}
        />
        {type === "table" && (
          <CustomItem
            title={"Rohbau"}
            isChecked={filterValues.includes("Rohbau")}
            onSwitch={switchHandler}
          />
        )}
      </Popover>
    </Aux>
  );
};

const mapStateToProps = state => {
  return {
    filterValues: state.search.titleValue
  };
};

const masDispatchToProps = dispatch => ({
  changeSeachParam: (property, value) =>
    dispatch(actionCreator.changeSearchParam(property, value)),
  changePerformerSearchParam: (property, value) =>
    dispatch(actionCreator.changePerformerSearchParam(property, value))
});

export default connect(
  mapStateToProps,
  masDispatchToProps
)(CustomPopover);
