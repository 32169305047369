import * as actionTypes from "../actions/actionTypes";

const initState = {
  products: {},
  productsLoading: false,
  errorMessage: ""
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_TABLE_LIST_START:
      return { ...state, productsLoading: true };
    case actionTypes.GET_PRODUCT_TABLE_LIST_SUCCESS:
      return { ...state, productsLoading: false, products: action.payload };
    case actionTypes.GET_PRODUCT_TABLE_LIST_FAILURE:
      return { ...state, productsLoading: false, errorMessage: action.payload };
    default:
      return state;
  }
};

export default reducer;
