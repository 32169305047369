const growthRateStyles = theme => ({
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  filterButton: {
    height: "25px",
    width: "120px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "5px"
    }
  }
});

export default growthRateStyles;
