import * as actionTypes from "../actions/actionTypes";
import HighPerformerService from "../../services/HighPerformerService";

const currentDate = HighPerformerService.getCurrentDate();
const { year } = currentDate;

const initState = {
  searchParams: {
    category: "Wohnung",
    state: "Berlin",
    year: year,
    month: "01",
    actual_time: `${year}-01`,
    titleValue: ["Erbpacht", "Erbbaurecht"],
  },
  shortTermDataLoading: false,
  longTermDataLoading: false,
  shortTermRentDataLoading: false,
  shortTermBuyDataLoading: false,
  objectTableLoading: false,
  shortTermData: {},
  longTermData: {},
  shortTermRentData: {},
  shortTermBuyData: {},
  objectTableData: {}
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PERFORMER_SEARCH_PARAM:
      return {
        ...state,
        searchParams: { ...state.searchParams, [action.name]: action.value }
      };
    case actionTypes.GET_SHORT_TERM_PERFORMER_DATA_START:
      return {
        ...state,
        shortTermDataLoading: true
      };
    case actionTypes.GET_LONG_TERM_PERFORMER_DATA_START:
      return {
        ...state,
        longTermDataLoading: true
      };
    case actionTypes.GET_SHORT_TERM_RENT_PERFORMER_DATA_START:
      return {
        ...state,
        shortTermRentDataLoading: true
      };
    case actionTypes.GET_SHORT_TERM_BUY_PERFORMER_DATA_START:
      return {
        ...state,
        shortTermBuyDataLoading: true
      };
    case actionTypes.GET_OBJECT_TABLE_DATA_START:
      return {
        ...state,
        objectTableLoading: true
      };
    case actionTypes.GET_LONG_TERM_PERFORMER_DATA_SUCCESS:
      return {
        ...state,
        longTermData: action.data,
        longTermDataLoading: false
      };
    case actionTypes.GET_SHORT_TERM_PERFORMER_DATA_SUCCESS:
      return {
        ...state,
        shortTermData: action.data,
        shortTermDataLoading: false
      };
    case actionTypes.GET_SHORT_TERM_RENT_PERFORMER_DATA_SUCCESS:
      return {
        ...state,
        shortTermRentData: action.data,
        shortTermRentDataLoading: false
      };
    case actionTypes.GET_SHORT_TERM_BUY_PERFORMER_DATA_SUCCESS:
      return {
        ...state,
        shortTermBuyData: action.data,
        shortTermBuyDataLoading: false
      };
    case actionTypes.GET_OBJECT_TABLE_DATA_SUCCESS:
      return {
        ...state,
        objectTableData: action.data,
        objectTableLoading: false
      };
    default:
      return state;
  }
};

export default reducer;
