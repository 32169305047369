export default {
  root: {
    overflowY: "scroll",
    height: "500px",
    padding: "0 18px"
  },
  socLoader: {
    position: "relative",
    height: "400px",
    width: "100%"
  },
  customCard: {
    marginTop: "0 !important"
  },
  sociographic: { padding: "20px !important" },
  news: { position: "relative" },
  icon: { width: "1.5em" },
  classFlex: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  },
  notificationWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "120px",
    fontWeight: "bold",
    fontSize: "1.75rem",
    "& h4": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
      height: "100%",
      width: "96%",
      backgroundColor: "white",
      borderRadius: "5px"
    }
  },
  cardToolClass: {
    display: "flex",
    alignItems: "center"
  }
};
