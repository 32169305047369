import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";

import styles from "./sqrtAvatarStyle";

const useStyles = makeStyles(styles);

const SqrtAvatar = props => {
  const classes = useStyles();
  const { src, alt, fullWidth } = props;

  const size = fullWidth ? classes.fullWidth : classes.large;

  return (
    <div className={classes.root}>
      <Avatar
        alt={alt}
        src={src}
        className={size}
        style={{
          margin: 0
        }}
      />
    </div>
  );
};

export default SqrtAvatar;
