import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../unitily";

const initState = {
  featuresSearch: [
    {
      lat: "",
      lng: "",
      city: "",
      zip: "",
      streetname: "",
      objectName: "Object A",
      isTyping: false
    }
  ],
  featuresSearchResults: [
    {
      socialStatuses: {},
      typeSuggestions: [],
      featureMarkers: [],
      rectangleMarkers: []
    }
  ],
  chechBoxes: [],
  responseError: [],
  isFetureSearchSplitted: false,
  suggestionsObject: {}
};

const setFeaturesSearchParam = (state, action) => {
  const updState = { ...state };
  if (action.payload.property === "markers") {
    if (updState.markers.length <= 0) {
      updState[action.payload.property] = action.payload.value;
    }
    if (action.payload.value.length === 0) {
      updState[action.payload.property] = [];
    }
  } else {
    updState[action.payload.property] = action.payload.value;
  }
  return updateObject(state, {
    ...updState
  });
};

const setFeaturesSearchNestedParam = (state, action) => {
  const { property, value, index } = action.payload;
  const updState = { ...state };
  updState.featuresSearch[index][property] = value;

  return updateObject(state, {
    ...updState
  });
};

const setFeaturesSearchResults = (state, action) => {
  const { property, value, index } = action.payload;
  const updState = { ...state };
  updState.featuresSearchResults[index][property] = value;

  return updateObject(state, {
    ...updState
  });
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.SET_FEATURES_SEARCH_PARAM:
      return setFeaturesSearchParam(state, action);
    case actionTypes.SET_FEATURES_SEARCH_RESULTS:
      return setFeaturesSearchResults(state, action);
    case actionTypes.CHANGE_FEATURES_SEARCH_PARAM:
      return setFeaturesSearchNestedParam(state, action);
    default:
      return state;
  }
};

export default reducer;
