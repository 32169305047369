export default {
  table: {
    marginTop: "30px"
  },
  errorMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "red",
    width: "100%"
  }
};
