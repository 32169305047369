/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import {
  Dashboard,
  Search,
  ViewListSharp,
  Notifications,
  List
} from "@material-ui/icons";

// core components/views for Admin layout
import DashboardPage from "../views/Dashboard/Dashboard";
import SearchPage from "../views/Search/Search";
import ProductPage from "../views/SingleProduct/SingleProduct";
import VariancePage from "../views/Variance/Variance";
import NotificationPage from "../views/Notification/Notification";
import FeatureSearch from "../views/FeatureSearch/FeatureSearch";
import MarketAnalyse from "../views/MarketAnalyse/MarketAnalyse.view";
import ProductTable from "../views/ProductTable/ProductTable.view";
import SingleProduct from "../views/ProductTable/SingleProduct.view";
import HighPerformer from "../views/HighPerformer/HighPerformer.view";
import ObjectPropertyView from "../components/HighPerformer/ObjectPropertyView.component";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/search",
    name: "Search",
    icon: Search,
    component: SearchPage,
    layout: "/admin"
  },
  {
    path: "/property-view/:propertyId",
    component: ProductPage,
    layout: "/admin"
  },
  {
    path: "/variance",
    name: "Variance",
    icon: ViewListSharp,
    component: VariancePage,
    layout: "/admin"
  },
  {
    path: "/alarms",
    name: "Alarms",
    icon: Notifications,
    component: NotificationPage,
    layout: "/admin"
  },
  {
    path: "/feature-search",
    name: "Feature Search",
    icon: Search,
    component: FeatureSearch,
    layout: "/admin"
  },
  {
    path: "/market-analyse",
    name: "Market Analyse",
    icon: Search,
    component: MarketAnalyse,
    layout: "/admin"
  },
  {
    path: "/product-table",
    name: "Product Table",
    icon: List,
    component: ProductTable,
    layout: "/admin"
  },
  {
    path: "/single-product/:productId",
    component: SingleProduct,
    layout: "/admin"
  },
  {
    path: "/high-performer",
    name: "High Performer",
    icon: ViewListSharp,
    component: HighPerformer,
    layout: "/admin"
  },
  {
    path: "/object/:objectId",
    component: ObjectPropertyView,
    layout: "/admin"
  }
];

export default dashboardRoutes;
