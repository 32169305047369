const formStyle = theme => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  splitFormsContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  message: {
    padding: "20px",
    textAlign: "center"
  },
  mgTop3: {
    marginTop: "20px"
  },
  mgTop35: {
    marginTop: "35px"
  },
  filterButton: {
    height: "20px",
    width: "50px"
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  fab: {
    background: "#9c27b0",
    height: "40px",
    width: "40px",
    "&:hover": {
      background: "purple"
    }
  },
  notification: {
    color: "red",
    marginLeft: "10px"
  }
});

export default formStyle;
