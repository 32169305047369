import { grayColor } from "assets/jss/material-dashboard-react";

const featuresStyle = theme => ({
  featuresWrapper: {
    display: "flex",
    flexWrap: "wrap"
  },
  plzColorPrimary: {
    backgroundColor: "lightpink"
  },
  plzBarColorPrimary: {
    backgroundColor: "crimson"
  },
  countryColorPrimary: {
    backgroundColor: "moccasin"
  },
  countryBarColorPrimary: {
    backgroundColor: "yellow"
  },
  cityColorPrimary: {
    backgroundColor: "palegreen"
  },
  cityBarColorPrimary: {
    backgroundColor: "lightseagreen"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  progressHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      margin: 0
    }
  },
  searchResult: {
    position: "relative"
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "column"
  },
  infoText: {
    textAlign: "justify",
    padding: "8px"
  },
  featuresText: {
    fontSize: "14px",
    textAlign: "justify",
    display: "flex",
    alignItems: "center"
  },
  splitResultsContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  red: {
    backgroundColor: "crimson",
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    border: "1px solid grey"
  },
  green: {
    backgroundColor: "lightseagreen",
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    border: "1px solid grey"
  },
  yellow: {
    backgroundColor: "yellow",
    height: "15px",
    width: "15px",
    borderRadius: "50%",
    border: "1px solid grey"
  },
  mapContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  splittedMapContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  visibleInfoTextBlock: {
    display: "flex"
  },
  inVisibleInfoTextBlock: {
    display: "none"
  }
});

export default featuresStyle;
