// LIBRARIES
import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

// COMPONENTS
import Loader from "../../../ui/Loader/Loader";
import NewsAndStatista from "../../Search/NewsAndStatista";

import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import CardTool from "../CardTool";
import SociographicService from "../../../services/SociographicService";
import Chart from "../../Chart/Chart";
import SelectedChart from "../../Chart/SelectedChart";
import * as actionCreator from "../../../store/actions";
import styles from "../styles/tabs/sociographicStyle";
import { onDrawHandler } from "../../../variables/charts";

class Sociographic extends Component {
  constructor() {
    super();
    this.state = {
      chartData: {}
    };
  }

  componentDidUpdate(prevProps) {
    const { randomText } = this.props;
    if (randomText !== prevProps.randomText) {
      this.setState({ chartData: this.props.results.sociographic });
    }
  }

  onChartClick = (type, color, data, title, subtitle, subText) => {
    this.props.setParams("selectedChartParams", {
      type,
      color,
      data,
      title,
      subtitle,
      subText
    });
    this.props.toggleModals({
      isSelectedChart: true
    });
  };

  onChartClose = () => {
    this.props.toggleModals({
      isSelectedChart: false
    });
    this.props.setParams("selectedChartParams", {});
  };

  render() {
    const { classes } = this.props;
    const data = this.props.results.sociographic;
    const isDataEmpty = SociographicService.checkForNulls(data.totalHouseHolds);

    const unemploymentRate = data["Unemployment Rate"];
    const employmentRate = data["Employment Rate"];
    const totalMale = parseInt(data.male_total)?.toLocaleString();
    const totalFemale = parseInt(data.female_total)?.toLocaleString();
    const maleChartData = SociographicService.generateChartData(data.male);
    const femaleChartData = SociographicService.generateChartData(data.female);
    const totalHouseHoldsChartData = SociographicService.generateChartData(
      data.totalHouseHolds
    );
    const monthlyIncomeChartData = SociographicService.generateChartData(
      data.monthlyIncome
    );

    return (
      <GridContainer>
        {/*selects for filtered male or female start*/}
        {data ? (
          <React.Fragment>
            <GridItem xs={12} sm={12} md={6}>
              <CardTool
                className={classes.cardToolClass}
                color={"primary"}
                title={`Unemployment Rate ${unemploymentRate} %`}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CardTool
                className={classes.cardToolClass}
                color={"primary"}
                title={`Employment Rate ${employmentRate} %`}
              />
            </GridItem>
            {!isDataEmpty ? (
              <div className={classes.classFlex}>
                {this.props.isSelectedChart && (
                  <SelectedChart
                    onChartClose={this.onChartClose}
                    isSidebarToggled={this.props.isSidebarToggled}
                    params={this.props.selectedChartParams}
                  />
                )}
                {/*male chart starts*/}
                <GridItem xs={12} sm={12} md={12}>
                  <Chart
                    type={"Bar"}
                    color={"success"}
                    data={maleChartData}
                    title={`Age of male residences ${totalMale}`}
                    onDrawHandler={e => onDrawHandler(e, 3.2)}
                    onChartClick={() =>
                      this.onChartClick(
                        "Bar",
                        "success",
                        maleChartData,
                        `Age of male residences ${totalMale}`,
                        ""
                      )
                    }
                  />
                </GridItem>
                {/*male chart ends*/}
                {/*female chart starts*/}
                <GridItem xs={12} sm={12} md={12}>
                  <Chart
                    type={"Bar"}
                    color={"success"}
                    data={femaleChartData}
                    title={`Age of female residences ${totalFemale}`}
                    onDrawHandler={e => onDrawHandler(e, 3.2)}
                    onChartClick={() =>
                      this.onChartClick(
                        "Bar",
                        "success",
                        femaleChartData,
                        `Age of female residences ${totalFemale}`
                      )
                    }
                  />
                </GridItem>
                {/*female chart ends*/}
                {/*households by age chart data starts*/}
                <GridItem xs={12} sm={12} md={12}>
                  <Chart
                    type={"Bar"}
                    color={"success"}
                    data={totalHouseHoldsChartData}
                    title={"Age of the households"}
                    onDrawHandler={e => onDrawHandler(e, 2.9)}
                    onChartClick={() =>
                      this.onChartClick(
                        "Bar",
                        "success",
                        totalHouseHoldsChartData,
                        "Age of the households"
                      )
                    }
                  />
                </GridItem>
                {/*households by age chart data ends*/}
                {/*households monthly income chart data starts*/}
                <GridItem xs={12} sm={12} md={12}>
                  <Chart
                    type={"Bar"}
                    color={"success"}
                    data={monthlyIncomeChartData}
                    title={"Monthly NET income of the households"}
                    onDrawHandler={e => onDrawHandler(e, 2.5)}
                    onChartClick={() =>
                      this.onChartClick(
                        "Bar",
                        "success",
                        monthlyIncomeChartData,
                        "Monthly NET income of the households"
                      )
                    }
                  />
                </GridItem>
              </div>
            ) : (
              <div className={classes.notificationWrapper}>
                <h4>Sorry, there was no data found for the searched city</h4>
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className={classes.socLoader}>
            <Loader />
          </div>
        )}
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ position: "relative" }}
        >
          {this.props.loaders.nearByInfo ? <Loader /> : ""}
          {this.props.searchFormParams.address !== "" &&
          this.props.results.totalResults !== "" &&
          this.props.results.city !== "" ? (
            <NewsAndStatista city={this.props.results.city} />
          ) : (
            ""
          )}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  const { search, results, ui } = state;
  return {
    searchFormParams: {
      address: search.address
    },
    searchParams: {
      categories: search.categories
    },
    results: {
      city: results.city,
      totalResults: results.totalResults,
      sociographic: results.sociographic
    },
    loaders: {
      socLoading: ui.socLoading,
      nearByInfo: ui.nearByInfo
    },
    isSelectedChart: ui.isSelectedChart,
    isSidebarToggled: ui.isSidebarToggled,
    selectedChartParams: results.selectedChartParams,
    randomText: results.randomText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModals: value => dispatch(actionCreator.toggleModals(value)),
    setParams: (property, value) => dispatch(actionCreator.set(property, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Sociographic));
