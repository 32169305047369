import TableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";

export const StyledTableCellTopBottom = withStyles(theme => ({
  head: {
    backgroundColor: "purple",
    color: theme.palette.common.white,
    borderWidth: 2,
    borderColor: "white",
    borderStyle: "solid"
  },
  body: {
    fontSize: 14,
    backgroundColor: "purple",
    color: theme.palette.common.white,
    borderWidth: 2,
    borderColor: "white",
    borderStyle: "solid"
  }
}))(TableCell);

export const StyledTableCell = withStyles(theme => ({
  body: {
    fontSize: 14,
    borderWidth: 2,
    borderColor: "white",
    borderStyle: "solid"
  }
}))(TableCell);

export const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);
