import api from "../config/api";

const HighPerformerApi = {
  getShorttermPerformer({ state, category }, rowsPerPage, page, days) {
    return api.get("high-performer/shortterm", {
      params: {
        state,
        category,
        rowsPerPage,
        page,
        days
      }
    });
  },
  getLongtermPerformer({ state, category, actual_time }, rowsPerPage, page) {
    return api.get("high-performer/longterm", {
      params: {
        state,
        category,
        actual_time,
        rowsPerPage,
        page
      }
    });
  },
  getShortTermRentPerformer({ state, category }, rowsPerPage, page, days) {
    return api.get("high-performer/shortterm_qm", {
      params: {
        state,
        category,
        rowsPerPage,
        page,
        days
      }
    });
  },
  getShortTermBuyPerformer({ state, category }, rowsPerPage, page, days) {
    return api.get("high-performer/shortterm_qm_for_buy", {
      params: {
        state,
        category,
        rowsPerPage,
        page,
        days
      }
    });
  },
  getObjectTableData({ state, category, actual_time, titleValue }, rowsPerPage, page) {
    return api.get("high-performer/objects_mietrendite", {
      params: {
        state,
        category,
        actual_time,
        titleValue,
        rowsPerPage,
        page
      }
    });
  },
};

export default HighPerformerApi;
